import React, { useState, useEffect } from "react";

import { SAdefaultValues, selectDateOrTime } from "../../../../../services/saleAnalysis-services";
import {
    Button,
    Chip,
    colors,
    Container,
    Dialog,
    Grid,
    Hidden,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";
import { uri } from "../../../../../help/Api";
import TotalCard from "../../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
    useStylesHed,
    useStylesSpDial,
    useStylesUiElemment,
} from "../../../../../St/comps/UseStyle";
import { insh as inshst } from "../../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import i18next from "i18next";
import { TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import moment from "moment";
import axios from "axios";
import { sto } from "../../../../../Context/InshData";

import ReplyIcon from "@material-ui/icons/Reply";
import deepRenameKeys from "deep-rename-keys";
import RetrictionsSearchForm from "./RetrictionsSearchForm";
import RetrictionsSearchTable from "./RetrictionsSearchTable";

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
    },
    itemW: {
        // display:'flex',
        width: "90%",
    },
    headerTitle: {
        backgroundColor: "#d3d3d3",
        textAlign: "center",
        fontSize: 20,
        width: "100%",
    },

    allWidth: {
        minWidth: 650,
    },
    checStyl: {
        marginTop: "17px",
        fontSize: "16px",
        padding: "-1px",
    },
    purchTotalCard: {
        backgroundColor: "#d3d3d3",
        borderColor: "#808080",
    },
}));

export default function RetrictionsSearchMain(props) {
    const {          openSearchMdl,  handCloseDw,
        onRowClick ,type,AutoOptions,transition
    } = props;

      let nameUser=AutoOptions.Users[0].name;
      let idUser=AutoOptions.Users[0].id;
    const initialSearchObject = {
        dateFrom: moment(new Date().setDate(new Date().getDate() - 1)).format("YYYY-MM-DD"),
        timeFrom: moment(new Date()).startOf("day").format("HH:mm"),
        dateTo: moment(new Date()).format('YYYY-MM-DD'),
        timeTo: moment(new Date()).format('HH:mm'),
        user: { id: sto.User_typei == 1 ? -1 : idUser, name: sto.User_typei == 1 ? 'الكل' : nameUser },
        branch:  sto.branchi,
        date_time: "false",
        refno: "",
        doc_no2: "",
        notes:"",
        type:type,
        deptval:'',
        creditval:'',
        center: { id: -1, name: "الكل" },
    };

 
    const [rptParams, setRptParams] = React.useState(initialSearchObject);
    const classes = useStyles();
    const clasui = useStylesUiElemment();
    const clasSp = useStylesSpDial();
    const { t } = useTranslation(["forms", "translation"]);

    const [data, setData] = useState([]);
    const [fetching, setFetching] = useState(false);
    const viewHandle = async () => {

        setData([]);
        setFetching(true);
        await axios
            .get(
              uri+
                `AccountReports/RestrictionSearch?branch=${rptParams?.branch}
        &&doc_no2=${rptParams?.doc_no2}
        &&fDate=${rptParams?.dateFrom + ' ' + rptParams.timeFrom}
        &&tDate=${rptParams?.dateTo + ' ' + rptParams.timeTo}
        &&notes=${rptParams?.notes}
        &&center=${rptParams.center?.id}
        &&user=${rptParams?.user.id}
       &&type=${rptParams?.type}
        &&deptVal=${rptParams?.deptval}
        &&creditVal=${rptParams?.creditval}`
            )
            .then((res) => {
                setData(res.data);
                console.log(" the data is", res.data);
                setFetching(false);
            })
            .catch((err) => {
                setFetching(false);
                console.log(err);
                // toast.err("يوجد خطأ في الجلب");
                setData([]);
            });
    };
    const his = useHistory();


    let excel_name = "Restrictions";
    var columns = [
        { title: t("no"), field: "doc_no2", },
        { title: t("date"), field: "date",},

        {
          title: "اجمالي المدين",
          field: "totDept",
        
        },
        {
          title:"حالة القيد",
          field: "totDept",
        
        },
        {
          title: "اجمالي الدائن",
          field: "totCredit",
       
        },
        {
          title: t("note"),
          field: "notes",
        
        },
       
      ];
      const excelHeaders = deepRenameKeys(columns, (key) => {
        if (key === "field") return "key";
        if(key === "title") return "label";  
      });
    
    useEffect(() => {
        console.log("the data is", rptParams);
    });

    const onChangeData = (propName) => (event) => {
        setRptParams({ ...rptParams, [propName]: event.target.value });
    };
    const onChangeAutoData = (propName, newValue) => {
        setRptParams({ ...rptParams, [propName]: newValue });      
    };
  
    
    return (
        <Dialog
        fullScreen
        open={openSearchMdl}
        onClose={handCloseDw}
        TransitionComponent={transition}
        key={transition ? transition.name : ""}
        >
            <div className={classes.root}>
                <Grid container item xs={12}>
                    <Typography
                        size="small"
                        className={classes.headerTitle}
                        color="primary"
                    >
                        {" البـحـث فـي الفيود "}
                    </Typography>
                </Grid>
                <Typography
                    component="div"
                    style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
                >
                    <Container
                        component={Paper}
                        maxWidth={false}
                        style={{
                            padding: "10px",
                            paddingBottom: "0px",
                            borderBottomColor: "#808080",
                            borderBottomWidth: "1px",
                            borderBottomStyle: "solid",
                        }}
                    >
                        <RetrictionsSearchForm
                            {...{
                                type,
                                 rptParams,
                                onChangeData,
                                onChangeAutoData,
                              AutoOptions,
                            }}
                        />
                        <Grid
                            container
                            justifyContent="center"
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            spacing={1}
                        >
                            <Hidden smUp={["sm"]}>
                                <Grid item>
                                    <Button
                                        disabled={fetching}
                                        variant="contained"
                                        startIcon={<ScannerIcon />}
                                        size="large"
                                        style={
                                            ( inshst.sal.iconBtnAcc,
                                                { borderRadius: 0 })
                                        }
                                        className={[clasui.buttonAcc]}
                                        onClick={() => viewHandle()}
                                    >
                                        {t("forms:show")}
                                    </Button>
                                </Grid>
                            </Hidden>
                            <Hidden only={["xs"]}>
                                <Grid item>
                                    <Button
                                        disabled={fetching}
                                        variant="contained"
                                        startIcon={<ScannerIcon />}
                                        size="large"
                                        style={
                                            (inshst.sal.iconBtnAcc,
                                                { borderRadius: 0 })
                                        }
                                        className={[clasui.buttonAcc]}
                                        onClick={() => viewHandle()}
                                    >
                                        {t("forms:view")}
                                    </Button>
                                </Grid>
                            </Hidden>
                            <Hidden smUp={["sm"]}>

                                <Grid item >
                                    {data.restrictionData && !fetching && (
                                        <CSVLink
                                            separator={";"}
                                            filename={excel_name}
                                            data={data.restrictionData}
                                            headers={excelHeaders}
                                        >
                                            {" "}
                                            <Button
                                                disabled={fetching}
                                                variant="contained"
                                                startIcon={<TableChart />}
                                                size="large"
                                                color="primary"
                                                style={{ borderRadius: 0 }}
                                                className={[clasui.buttonAcc]}
                                            >
                                                {"اكسل"}
                                            </Button>
                                        </CSVLink>
                                    )}
                                </Grid>
                            </Hidden>
                            <Hidden only={["xs"]}>
                                <Grid item >
                                    {data.restrictionData && !fetching && (
                                        <CSVLink
                                            separator={";"}
                                            filename={excel_name}
                                            data={data.restrictionData}
                                            headers={excelHeaders}
                                        >
                                            {" "}
                                            <Button
                                                disabled={fetching}
                                                variant="contained"
                                                startIcon={<TableChart />}
                                                size="large"
                                                color="primary"
                                                style={{ borderRadius: 0 }}
                                                className={[clasui.buttonAcc]}
                                            >
                                                {"تصدير اكسل"}{" "}
                                            </Button>
                                        </CSVLink>
                                    )}
                                </Grid>
                            </Hidden>
                           
                            <Grid item>
                                <Button
                                    variant="contained"
                                    // startIcon={<KeyboardReturnSharpIcon />}
                                    startIcon={<ReplyIcon />}
                                    color="secondary"
                                    size="large"
                                    aria-label="open drawer"
                                    className={[clasui.button, clasSp.clcan]}
                                    style={
                                        ( inshst.sal.iconBtn,
                                            { borderRadius: 0 })
                                    }
                                   onClick={() => handCloseDw()}
                                >
                                    {/* {t("forms:exit")} */}
                                    {"رجوع"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container maxWidth={true} style={{ padding: "4px" }}>
                        <Grid container spacing={1} style={{ paddingTop: "10px" }}>
                            
                            <Grid item lg={2} md={3} sm={4} xs={6}>
                                <TotalCard
                                    {...{
                                        className:  classes.purchTotalCard ,
                                        title: " عدد السجلات",
                                        value: data?.restrictionData?.length,
                                    }}
                                />
                            </Grid>
                            <Grid item lg={2} md={3} sm={4} xs={6}>
                                <TotalCard
                                    {...{
                                        className: classes.purchTotalCard,
                                        title: "اجمالي الدائن: ",
                                        value: (data?.restrictionData?.reduce((a, v) => (a = a + v.totDept), 0).toFixed(2)),
                                    }}
                                />
                            </Grid>
                            <Grid item lg={2} md={3} sm={4} xs={6}>
                                <TotalCard
                                    {...{
                                        className: classes.purchTotalCard,
                                        title: "اجمالي الدائن: ",
                                        value: (data?.restrictionData?.reduce((a, v) => (a = a + v.totCredit), 0).toFixed(2)),
                                    }}
                                />
                            </Grid>

                          
                        </Grid>
                    </Container>
                    <br />
                    <Container
                        component={Paper}
                        maxWidth={false}
                        style={{
                            paddingRight: "5px",
                            paddingLeft: "5px",
                        }}
                    >
                        <Grid container justifyContent="center">
                            <Grid item container justifyContent="center" xs={12}>
                                <BeatLoader loading={fetching} color="#808080"/>
                                {!fetching && (
                                    <RetrictionsSearchTable
                                        {...{
                                            //  data: data ? data : [],
                                            data: data?.restrictionData ? data.restrictionData : [],
                                            onRowClick,rptParams
                                        }}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Container>
                </Typography>
            </div>
        </Dialog>
    );
}
