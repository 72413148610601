import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { insh as inshst } from '../../../Context/InshSt';
import DocumetDialog from '../../Accounts/BackEnd/receipt-voucher/components/DocumentDialog';
import { Button, IconButton } from '@material-ui/core';
import { Pageview, Visibility } from '@material-ui/icons';
import { reFormatDate } from '../../../utils/utils';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}));

export default function PaymentTransactionRPTTable(props) {
  const stylH = {
    // borderWidth: 1,
    border: "solid 0.6px",
    borderColor: "black",
    borderStyle: "solid",
    fontFamily: "Amiri-Regular",
    fontWeight: 700,
    fontSize: 16,
    color: "#fff"
  }
  const stylRo = {
    BorderBottom: "solid 0.5px",
    //borderStyle: "solid",
    borderColor: "gray",
    fontFamily: "Amiri-Regular",
    fontWeight: 700,
    fontSize: 14,
    color: "#000"
  }

  // const [docOpen ,setDecOpen] = useState(false);
  const { data, fetchDataClick, title } = props;
  const [docData, setDocData] = useState({ docType: '', id: -1, docOpen: false });

  const onClose = async (reloaing = false) => {
    setDocData({ ...docData, docOpen: false });
    if (reloaing)
      await fetchDataClick();
  }
  const classes = useStyles();
  return (
    <TableContainer component={Paper} >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: "#81007f",
              color: "#000",
              fontWeight: 800,
              fontFamily: "Amiri-Regular",
              fontSize: 15,
              border: "solid 0.6px"
            }}
          >
            <TableCell style={stylH} align="center">{"رقم الحساب "}</TableCell>
            <TableCell style={stylH} align="center">{title}</TableCell>
            <TableCell style={stylH} align="center">{"الهاتف"}</TableCell>
            <TableCell style={stylH} align="center">{"الجوال"} </TableCell>
            <TableCell style={stylH} align="center">{"قيمة اخر سداد "}</TableCell>
            <TableCell style={stylH} align="center">{"تاريخ اخر سداد "}</TableCell>
            <TableCell style={stylH} align="center">{"نوع المستند "}</TableCell>
            <TableCell style={stylH} align="center">{"الرصيد"} </TableCell>
            <TableCell style={stylH} align="center">{"الحالة"}</TableCell>
            <TableCell style={stylH} align="center">{"عـــرض"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow key={row.id}
              style={{
                padding: "5px 0px",
                textAlign: "-webkit-center",
                margin: "0px",
                whiteSpace: "nowrap",
                color: "#000",
                fontWeight: 800,
                fontFamily: "Amiri-Regular",
                fontSize: 15,
                border: "solid 0.6px"
              }}
            >
              <TableCell style={stylRo} align="center">{row?.acc_no}</TableCell>
              <TableCell style={stylRo} align="center">{row?.name}</TableCell>
              <TableCell style={stylRo} align="center">{row?.tel}</TableCell>
              <TableCell style={stylRo} align="center">{row?.mobile}</TableCell>
              <TableCell style={stylRo} align="center">{row?.AmtVale?.toFixed(2)}</TableCell>
              <TableCell style={stylRo} align="center">{reFormatDate(row?.Doc_date)}</TableCell>

              <TableCell style={stylRo} align="center">{row?.Doc_Type}</TableCell>
              <TableCell style={stylRo} align="center">{row?.Blance?.toFixed(2)}</TableCell>
              <TableCell style={stylRo} align="center">{row?.CaseBlance}</TableCell>
              <TableCell style={stylRo} align="center">
                <IconButton edge="start" color="inherit"
                  style={{ maxHeight: "0.7rem" }}
                  aria-label="open drawer"
                  disabled={row?.Doc_Type < 5 || row?.Doc_Type > 8}
                  onClick={() => {
                    if (row?.Doc_Type >= 5 && row?.Doc_Type <= 8)
                      setDocData({
                        docType: row?.Doc_Type,
                        id: row?.doc_no,
                        docOpen: true,
                      })
                  }}
                >
                  <Visibility size={"small"} style={{ color: `${(row?.Doc_Type >= 5 && row?.Doc_Type <= 8) ? '#81007f' : '#eee'}` }} />
                </IconButton>

              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {
        docData.docOpen &&
        <DocumetDialog {...{ ...docData, onClose }} />
      }
    </TableContainer>
  )
}