import React, { useEffect, useState } from "react";
import {
  Button,
  CardActionArea,
  CardContent,
  colors,
  Container,
  Grid,
  Hidden,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import BalanceCustRPTForm from "./balanceCustRPT-form";
import { useHistory } from "react-router-dom";

import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import {
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../St/Reports/UseStyle";
import moment from "moment";
import { insh as inshst } from "../../../Context/InshSt";
import { SAdefaultValues } from "./balanceCustRPT-services";
import BalanceCustRPTTable from "./balanceCustRPT-table";
import { URL } from "../../../api/customer";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { TableChart } from "@material-ui/icons";
import { balanceTypes } from "./balanceCustRPT-services";
import TotalsCard from "./TotalsCard";
import BalanceCutomerTable from "./balanceCustsTable";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#faf1f1",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },

  headerTitle: {
    backgroundColor: colors.red[30],
    textAlign: "center",
    fontSize: 20,
    width: "100%",
    fontWeight: "bold"
  },

  blCard: {
    backgroundColor: "#fff",
    borderColor: "#81007f",
  },
  root3: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: colors.red[300],
    direction: "rtl",
    marginTop: 5,
    padding: 5,
    // boxShadow:'box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
    boxShadow:
      "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;",
  },
}));

export default function BalanceCustRPT(props) {
  const { AutoOptions } = props;
  let from_date = moment(new Date()).subtract().format("YYYY") + "-01-01";
  const [rptParams, setRptParams] = React.useState({
    ...SAdefaultValues,
    dateFrom: from_date,
    account: AutoOptions?.Accindex[0],
  });
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();

  const [data, setData] = useState([]);
  const [totals, setTotals] = useState({});

  const [fetching, setFetching] = useState(false);

  const fetchDataClick = async () => {
    setFetching(true);
    setTotals({});
    console.log("the type is", rptParams?.type);
    await axios
      .get(
        URL +
        `/getBalance?type=${rptParams.type?.id}
    &&branch=${rptParams.branch.id}
    &&center=${rptParams.center?.id}
    &&dateFrom=${rptParams.dateFrom}
    &&dateTo=${rptParams.dateTo}
    &&acc_code=${rptParams.account?.id}
    &&caseBalance=${rptParams.payType?.id}
     &&user=${rptParams.user?.id}
       &&old=${rptParams?.oldBalance}
        &&open=${rptParams?.openBalance}
     `
      )
      .then((res) => {
        let nData = res?.data?.accountsData.map((d) => {
          return {
            ...d,
            balance: (d?.end_Dept + d?.end_Credit).toFixed(2),
            state: d?.end_Dept == 0 ? "-" : d?.end_Dept > 0 ? "مدين" : "دائن",
          };
        });
        setData(nData);
        setTotals(res?.data?.totals);
      });
    setFetching(false);
  };

  const [style, setStyle] = useState(inshst.custm);
  const { t } = useTranslation(["forms"]);
  useEffect(() => {
    console.log(" the balance ", data);
  }, []);
  const his = useHistory();
  const toBack = () => {
    his.push({ pathname: "/" });
  };
  const printHandle = () => {
    const info = {
      data: data,
      type: rptParams?.type?.id,
      tot: totals,
      kind_print: "2",
      from: rptParams?.type?.id == 0 ? "العميل - المورد ":rptParams?.type?.id == 1 ?"العميل":"المورد",
      title: rptParams?.type?.id == 0 ? "تقرير أرصدة حسابات العملاء /الموردين":rptParams?.type?.id == 1 ?"تقرير ارصدة حسابات العملاء":"تقرير ارصدة حسابات الموردين",
      account: rptParams?.account,
    
      fDate: rptParams?.dateFrom,
      tDate: rptParams?.dateTo,
      nDate: moment(new Date()).format("YYYY-MM-DD"),
      ElmntShow: true,
    };
    localStorage.setItem('iInfos',  JSON.stringify(info));
    his.push({ pathname: "/CustBalance", state: info });
    window.location.reload(false);
  };
  const printHandle2 = () => {
    const info = {
      data: data,
      type: rptParams?.type?.id,
      tot: totals,
      kind_print: "2",
      from: rptParams?.type?.id == 0 ? "العميل - المورد ":rptParams?.type?.id == 1 ?"العميل":"المورد",
      title: rptParams?.type?.id == 0 ? "تقرير أرصدة حسابات العملاء /الموردين":rptParams?.type?.id == 1 ?"تقرير ارصدة حسابات العملاء":"تقرير ارصدة حسابات الموردين",
      account: rptParams.account,
      branch:rptParams.branch.name,
      dateFrom: rptParams.dateFrom,
      dateTo: rptParams.dateTo,
      nDate: moment(new Date()).format("YYYY-MM-DD"),
      ElmntShow: true,
    };
    localStorage.setItem("iInfos", JSON.stringify(info));
    his.push({ pathname: "/CustBalance" });
    window.location.reload(true);
  };
  let excel_name = "";
  let lebel_name = "";
  if (rptParams?.type?.id == 0) {
    excel_name = "CustomersSuppliersReport"
    lebel_name = "العميل/المورد"
  } else if (rptParams?.type?.id == 1) {
    excel_name = "CustomersReport"
    lebel_name = "اسم العميل"
  } else {
    excel_name = "SuppliersReport"
    lebel_name = "اسم المورد"
  }
  const excelHeaders = [
    { label: "رقم الحساب", key: "Code" },
    { label: lebel_name, key: "aname" },
    { label: "مدين", key: "OBX_Dept" },
    { label: "دائن", key: "OBX_Credit" },
    { label: "حركة مدين", key: "dept" },
    { label: "حركة دائن", key: "credit" },
    { label: "رصيد نهائي مدين", key: "end_Dept" },
    { label: "رصيد نهائي دائن", key: "end_Credit" },
  ];

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };
  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography size="small" className={classes.headerTitle}>
          ارصدة حساب العملاء / الموردين
        </Typography>
      </Grid>
      <Typography component="div" style={{ direction: "rtl" }}>
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            paddingBottom: "0px",
            borderBottomColor: inshst.colors.cust,
            borderBottomWidth: "10px",
            borderBottomStyle: "solid",
          }}
        >
          <BalanceCustRPTForm
            {...{
              AutoOptions,
              rptParams,
              setRptParams,
              onChangeData,
              onChangeAutoData,
              printHandle,
            }}
          />
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >
            <Hidden only={["xs"]}>
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                size="large"
                style={{ ...style.iconBtn, borderRadius: 0 }}
                className={[clasui.button]}
                onClick={fetchDataClick}
              >
                {t("forms:view")}
              </Button>
            </Grid>
            </Hidden>

            <Hidden smUp={["sm"]}>
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                size="large"
                style={{ ...style.iconBtn, borderRadius: 0 }}
                className={[clasui.button]}
                onClick={fetchDataClick}
              >
                {t("forms:show")}
              </Button>
            </Grid>
            </Hidden>
            <Hidden only={["xs"]}>

            <Grid item >
              {data && !fetching && data && (
                <CSVLink
                  separator={";"}
                  filename={excel_name}
                  data={data}
                  headers={excelHeaders}
                >
                  {" "}
                  <Button
                    disabled={fetching}
                    variant="contained"
                    startIcon={<TableChart />}
                    size="large"
                    style={{ ...style.iconBtn, borderRadius: 0 }}
                    className={[clasui.button]}
                  >
                    {"تصدير اكسل"}{" "}
                  </Button>
                </CSVLink>
              )}
            </Grid>
            </Hidden>
            <Hidden smUp={["sm"]}>
            <Grid item >
              {data && !fetching && data && (
                <CSVLink
                  separator={";"}
                  filename={excel_name}
                  data={data}
                  headers={excelHeaders}
                >
                  {" "}
                  <Button
                    disabled={fetching}
                    variant="contained"
                    startIcon={<TableChart />}
                    size="large"
                    style={{ ...style.iconBtn, borderRadius: 0 }}
                    className={[clasui.button]}
                  >
                    {"اكسل"}{" "}
                  </Button>
                </CSVLink>
              )}
            </Grid>
            </Hidden>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.button, clasSp.clSec]}
                style={{ ...style.iconBtn, borderRadius: 0 }}
                // disabled={true}
                onClick={printHandle}
              >
                {t("forms:print")}{" "}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                style={{ ...style.iconBtn, borderRadius: 0 }}
                onClick={toBack}
              >
                {t("forms:exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={true} style={{ padding: "4px" }}>
          <Grid container spacing={1} style={{ paddingTop: "10px" }}>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalsCard
                {...{
                  title: t("initial-balance"),
                  dept: totals?.totOpenDept?.toFixed(2),
                  credit: totals?.totOpenCredit?.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={3} xs={6}>
              <TotalsCard
                {...{
                  title: t("prev-balance"),
                  dept: totals?.totOpenDept?.toFixed(2),
                  credit: totals?.totOpenCredit?.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={3} xs={6}>
              <TotalsCard
                {...{
                  title: t("acc-move"),
                  dept: totals?.totDept?.toFixed(2),
                  credit: totals?.totCredit?.toFixed(2),
                }}
              />
            </Grid>

            <Grid item lg={2} md={2} sm={4} xs={6}>
            
              <TotalsCard
                {...{
                  title: "رصيد الحساب",
                  dept: totals?.totEndDept?.toFixed(2),
                  credit: totals?.totEndCredit?.toFixed(2),
                }}
              />

            </Grid>
          </Grid>
        </Container>
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            // paddingTop:'5px',
          }}
        >
          <Grid container>
            {fetching ? (
              <TableContainer component={Paper}>
                {"يرجى الانتظار قليلا حتى يتم جلب البيانات ..."}
                <LinearProgress />
              </TableContainer>
            ) : (
              // <BalanceCustRPTTable {...{ data: data?.accountsData ? data?.accountsData : [], title: lebel_name }} />
              <BalanceCutomerTable {...{ data: data ? data : [], title: lebel_name }} />

            )}
          </Grid>
        </Container>
      </Typography>
    </div>
  );
}
