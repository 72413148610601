import React, { useState, useEffect } from "react";
import { txtA, txtE } from "../../../../Label/Lbl";
import BackdropScI from "../../../../services/BackdropScI";
import axios from "axios";
import { uri } from "../../../../help/Api";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";
import Paper from "@material-ui/core/Paper";
import { useBody } from "../style";
import { getTotalAmount } from "../../../../utils/utils";

const UnderHeader = (props) => {

  const{found,inv,sta,locInv}=props;
  const clasm = useInvMain();
  const clas = useInvAF();


  //const locInv = JSON.parse(localStorage.getItem("datainv")) || {};

  //const [sta, setSta] = useState({ info: [], tot: {} });
  const [showSc, setShowSc] = useState(true);
  const calssBody = useBody();
 
  const styf = { fontWeight: "500" };
  const styfC = { fontWeight: "500", color: "#9a3f47" };

  const styL = { textAlign:"left", paddingLeft:"0.5rem" };
  const styR = { textAlign:"right", paddingRight:"0.5rem" };

  const styRS = { textAlign:"right", paddingRight:"0.5rem" ,fontSize:"0.7rem" };
  const styLS = { textAlign:"right", paddingRight:"0.5rem" ,fontSize:"0.7rem" };
 
  return (
    <Grid
      item
      container
      className={clas.Bdyroot}
      style={{ fontWeight: "bold" ,fontFamily: "Amiri-Regular",marginLeft: "0cm",marginRight:"0rem",paddingTop:"0.5rem"}}

    >
      <Grid item xs={12}>
    

        <Grid item container style={{ direction: "rtl" }}>
          {/* <Grid item xs={1}  className={clas.Bdyroot}></Grid> */}
          <Grid item xs={6} style={{fontFamily: "Amiri-Regular",paddingTop:"0.3rem"}}
         
          >
            <Grid item container className={clasm.mincntr}>
            <Grid item xs={12}
            container
            style={{
              border: "solid 0.1px",
              backgroundColor: "#f5ebdb ",
              paddingTop: "0.3rem",
          }}>
             <Grid item xs={6}  style={{textAlign:"right",paddingRight:"0.5rem"}}>
            {"العميل"}
            </Grid>
                
            <Grid item xs={6} style={{textAlign:"left",paddingLeft:"0.5rem"}}>
            {"Customer"}
            </Grid>
           
              </Grid>
              <Grid item xs={2} className={clas.BdyborBtR} style={styR}>
                {"العميل"}
              </Grid>
              <Grid item xs={8} className={clasm.minborB} style={styfC}>
                <Paper className={clasm.minclr} style={{maxHeight:"1rem",minHeight:"1rem"}}>{sta.tot.cust_name}</Paper>
              </Grid>
              <Grid item xs={2} className={clas.BdyborBtL} style={styL}>
                {"Name"}
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtR} style={styR}>
                {"رقم المبنى"}

              </Grid>
              <Grid item xs={6} className={clasm.minborB} style={styfC}>
                <Paper className={clasm.minclr}>{sta.tot.tax_no_build_cust}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL} style={styL}>
              {"Build No"}
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtR} style={styR}>
              {"اسم الشارع"}
              </Grid>
              <Grid item xs={6} className={clasm.minborB} style={styfC}>
                <Paper className={clasm.minclr}>
                <Paper className={clasm.minclr}style={{maxHeight:"1rem",minHeight:"1rem"}}>{sta.tot.tax_name_street_cust}</Paper>

                  {/* {moment(sta.tot.date).format("YYYY/MM/DD")} */}
                </Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL} style={styL}>
                {"Street Name"}
              </Grid>

              <Grid item xs={3} className={clas.BdyborBtR} style={styR}>
              {"الحي"}

              </Grid>
              <Grid item xs={6} className={clasm.minborB} style={styfC}>
                <Paper className={clasm.minclr}>{sta.tot.tax_name_district_cust}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL} style={styL}>
                {"District"}
              </Grid>

              <Grid item xs={3} className={clas.BdyborBtR} style={styR}>
              {"المدينة"}

              </Grid>
              <Grid item xs={6} className={clasm.minborB} style={styfC}>
                <Paper className={clasm.minclr}>{sta.tot.tax_name_city_cust}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL} style={styL}>
                {"City"}

              </Grid>

              <Grid item xs={3} className={clas.BdyborBtR} style={styR}>
              {"البلد"}

              </Grid>
              <Grid item xs={6} className={clasm.minborB} style={styfC}>
                <Paper className={clasm.minclr}>{sta.tot.tax_name_country_cust}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL} style={styL}>
                {"Country"}
              </Grid>
              <Grid item xs={4} className={clas.BdyborBtR} style={styR}>
              {"الرمز البريدي"}

              </Grid>
              <Grid item xs={4} className={clasm.minborB} style={styfC}>
                <Paper className={clasm.minclr}>{sta.tot?.tax_zip_code_cust}</Paper>
              </Grid>
              <Grid item xs={4} className={clas.BdyborBtL} style={styL}>
                {"Postal Code"}

              </Grid>

              <Grid item xs={4} className={clas.BdyborBtR} style={styR}>
              {"الرقم للعنوان"}

              </Grid>
              <Grid item xs={4} className={clasm.minborB} style={styfC}>
                <Paper className={clasm.minclr}>{sta.tot?.tax_vat_registration_no_cust}</Paper>
              </Grid>
              <Grid item xs={4} className={clas.BdyborBtL} style={styL}>
                {"Additional No"}
              </Grid>

              <Grid item xs={3} className={clas.BdyborBtR} style={styR}>
                {txtA.taxNumr}
              </Grid>
              <Grid item xs={6} className={clasm.minborB} style={styfC}>
                <Paper className={clasm.minclr}>{sta.tot.tax_no_cust}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL} style={styL}>
                {txtE.taxNumr}
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtR} style={styR}>
                {"Other No"}
              </Grid>
              <Grid item xs={6} className={clasm.minborB} style={styfC}>
                <Paper className={clasm.minclr}>{sta.tot?.com_register}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL} style={styL}>
                {"Other No"}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{fontFamily: "Amiri-Regular",paddingTop:"0.3rem"}}
         
         >
           <Grid item container className={clasm.mincntr}>
           <Grid item xs={12} container  style={{
               border: "solid 0.1px",
               backgroundColor: "#f5ebdb ",
               paddingTop: "0.3rem",
           }}>
            <Grid item xs={6}  style={{textAlign:"right",paddingRight:"0.5rem"}}>
             {txtA.cusNM}
             </Grid>
               
             <Grid item xs={6}  style={{textAlign:"left",paddingLeft:"0.5rem"}}>
             {"Supplier"}
             </Grid>
             
             </Grid>
             <Grid item xs={2} className={clas.BdyborBtR} style={styR}>
               {txtA.cusNM}

             </Grid>
             <Grid item xs={8} className={clasm.minborB}>
               <Paper className={clasm.minclr} style={{maxHeight:"1rem",minHeight:"1rem"}}>{found.Disc1_header_ar}</Paper>
             </Grid>
             <Grid item xs={2} className={clas.BdyborBtL} style={styL}>
               {"Name"}
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtR} style={styR}>
               {"رقم المبنى"}

             </Grid>
             <Grid item xs={6} className={clasm.minborB} style={styfC}>
               <Paper className={clasm.minclr}>{sta.tot.tax_no_build_found}</Paper>
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtL} style={styL}>
             {"Build No"}
             </Grid>
             <Grid item xs={4} className={clas.BdyborBtR} style={styR}>
             {"اسم الشارع"}
             </Grid>
             <Grid item xs={4} className={clasm.minborB} style={styfC}>
               <Paper className={clasm.minclr}style={{maxHeight:"1rem",minHeight:"1rem"}}>{sta.tot.tax_name_street_found}</Paper>
             </Grid>
             <Grid item xs={4} className={clas.BdyborBtL} style={styL}>
               {"Street Name"}
             </Grid>

             <Grid item xs={3} className={clas.BdyborBtR} style={styR}>
             {"الحي"}

             </Grid>
             <Grid item xs={6} className={clasm.minborB} style={styfC}>
               <Paper className={clasm.minclr}>{sta.tot.tax_name_district_found}</Paper>
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtL} style={styL}>
               {"District"}
             </Grid>

             <Grid item xs={3} className={clas.BdyborBtR} style={styR}>
             {"المدينة"}

             </Grid>
             <Grid item xs={6} className={clasm.minborB} style={styfC}>
               <Paper className={clasm.minclr}>{sta.tot.tax_name_city_found}</Paper>
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtL} style={styL}>
               {"City"}

             </Grid>

             <Grid item xs={3} className={clas.BdyborBtR} style={styR}>
             {"البلد"}

             </Grid>
             <Grid item xs={6} className={clasm.minborB} style={styfC}>
               <Paper className={clasm.minclr}>{sta.tot.tax_name_country_found}</Paper>
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtL}  style={styL}>
               {"Country"}
             </Grid>
             <Grid item xs={4} className={clas.BdyborBtR} style={styR}>
             {"الرمز البريدي"}

             </Grid>
             <Grid item xs={4} className={clasm.minborB} style={styfC}>
               <Paper className={clasm.minclr}>{sta.tot.tax_zip_code_found}</Paper>
             </Grid>
             <Grid item xs={4} className={clas.BdyborBtL} style={styL}>
               {"Postal Code"}

             </Grid>

             <Grid item xs={4} className={clas.BdyborBtR} style={styR}>
             {"الرقم للعنوان"}

             </Grid>
             <Grid item xs={4} className={clasm.minborB} style={styfC}>
               <Paper className={clasm.minclr}>{sta.tot.tax_additional_address_no_found}</Paper>
             </Grid>
             <Grid item xs={4} className={clas.BdyborBtL} style={styL}>
               {"Additional No"}
             </Grid>

             <Grid item xs={3} className={clas.BdyborBtR} style={styR}>
               {txtA.taxNumr}
             </Grid>
             <Grid item xs={6} className={clasm.minborB} style={styfC}>
               <Paper className={clasm.minclr}>{sta.tot.tax_vat_registration_no_found}</Paper>
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtL} style={styL}>
               {txtE.taxNumr}
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtR} style={styR}>
               {"Other No"}
             </Grid>
             <Grid item xs={6} className={clasm.minborB} style={styfC}>
               <Paper className={clasm.minclr}>{sta.tot?.foundaion_bsn_no}</Paper>
             </Grid>
             <Grid item xs={3} className={clas.BdyborBtL} style={styL}>
               {"Other No"}
             </Grid>
           </Grid>
         </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
};

export default UnderHeader;
