import React, { useState, useEffect } from "react";

import { SAdefaultValues } from "../../../services/saleAnalysis-services";
import {
  Button,
  Chip,
  colors,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import useFetchURL from "../../../components/Hooks/useFetchURL";
import { uri } from "../../../help/Api";
import TotalCard from "../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import { useStylesSpDial } from "../../../St/comps/UseStyle";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import LoadingPage from "../../../services/LoadingPage";
import ChipsSelect from "../../../components/chips-select";
import {
  prepateBasicPerms,
  selectTypes,
} from "../../../services/permissions-services";
import UserForm from "./user-form";
import BasicPermissions from "./basic-permissions/basic-permissions";
import useFormDataCRUD from "../../../components/Hooks/useEDSCURD";
import { useSharedData } from "./user-permissions-con";
import { useAPI } from "../../../api/useAPI";
import SalePermissions from "./sale-permissions/sale-permissions";
import POSPermissions from "./sale-permissions/POS-permissions";
import PurchPermissions from "./purch-permissions";
import usePermUri from "./usePermUri";
import QutaionsPermission from "./QutaionsPermission/QutaionsPermission";
import OrderPermission from "./OrderPermission/OrderPermission";
import SafesPermisions from "./SafePermissions/SafesPermisions";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    backgroundColor: "#fff",
    padding: 5,
    textAlign: "center",
    fontSize: 24,
    width: "100%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
}));

export function UserPermissionSub(props) {
  const { users, data, fetching, gData, setGData, fetchURL } = props;
  const classes = useStyles();
  const clasSp = useStylesSpDial();
  const { t } = useTranslation(["forms", "translation"]);
  const { updateFun } = useAPI("UserPermission");
  // const {
  //   onChange,
  //   submit,
  //   update,
  //   remove,
  //   submitting,
  //   errorMessage,
  //   onShow,
  //   onEnd,
  //   object,
  //   formType,
  // } = useFormDataCRUD(
  //   () => {},
  //   updateFun,
  //   () => {},
  //   undefined,
  //   { ...data },
  //   null,
  //   fetchURL,
  //   "edit"
  // );

  const {
    onChange,
    submit,
    update,
    remove,
    submitting,
    errorMessage,
    onShow,
    onEnd,
    object,
    formType,
  } = usePermUri(
    () => { },
    updateFun,
    () => { },
    undefined,
    { ...data },
    null,
    fetchURL,
    "edit"
    // fetchURL
  );
  useEffect(() => {
    console.log("oboboobobbobob", object);
  });
  const his = useHistory();
  const handleUpdate = () => {
    let pData = prepateBasicPerms(object);
    let _Data = {
      ...pData,
      user_id: gData.user?.id,
      Form_id: gData.Form_id,
    };

    update(_Data);
  };
  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography
          size="small"
          className={classes.headerTitle}
          color="primary"
        >
          صلاحيات المستخدمين
        </Typography>
      </Grid>

      <Typography component="div" style={{ direction: "rtl" }}>
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            paddingBottom: "0px",
            borderBottomColor: colors.indigo[300],
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          <ChipsSelect
            items={selectTypes}
            selectedId={gData.id}
            onSelect={(x) => {
              setGData({ ...gData, Form_id: x.Form_id, id: x.id });
              console.log(x);
            }}
          />
          <UserForm users={users} gData={gData} setGData={setGData} />
        </Container>

        <br />
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <BeatLoader loading={fetching} color={colors.red[300]} />

              {gData.id == 1 && (
                <SalePermissions
                  object={object}
                  onChange={onChange}
                  update={handleUpdate}
                  submitting={submitting}
                />
              )}
              {gData.id == 2 && (
                <POSPermissions
                  object={object}
                  onChange={onChange}
                  update={handleUpdate}
                  submitting={submitting}

                />
              )}
              {gData.id == 3 && (
                <PurchPermissions
                  object={object}
                  onChange={onChange}
                  update={handleUpdate}
                  submitting={submitting}

                />
              )}
              {gData.id == 4 && (
                <BasicPermissions
                  object={object}
                  onChange={onChange}
                  update={handleUpdate}
                  submitting={submitting}

                />
              )}
              {gData.id == 5 && (
                <QutaionsPermission
                  object={object}
                  onChange={onChange}
                  update={handleUpdate}
                  submitting={submitting}
                />
              )}
              {gData.id == 6 && (
                <OrderPermission
                  object={object}
                  onChange={onChange}
                  update={handleUpdate}
                  submitting={submitting}
                />
              )}
              {gData.id == 7 && (
                <SafesPermisions
                  object={object}
                  onChange={onChange}
                  update={handleUpdate}
                  submitting={submitting}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Typography>
    </div>
  );
}

export default function UserPermissions(props) {
  const [gData, setGData] = useState(props.gData);
  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url:
        uri +
        `UserPermission/getWithUserTable?id=${gData?.user?.id}
    &&f=${gData.Form_id}`,
      dependencyArray: [gData],
    },
  });
  useEffect(() => {
    console.log("the globel data is", data);
  });
  if (fetching || !data) return <LoadingPage />;
  return (
    <UserPermissionSub
      users={props.users}
      fetching={fetching}
      data={{ ...data?.perms, ...data?.user }}
      gData={gData}
      setGData={setGData}
      fetchURL={fetchURL}
    />
  );
}
