import { useState } from "react";
import {get} from 'lodash'
import toast from "react-hot-toast";
import moment from "moment";

export default function usePermUri(
    submitFunction,
    updateFunction,
    removeFunction,
    key,
    _baseValues ={},
    onShowFunction,
    onEndFunction,
    defaultType= 'new',
    GetRowFunction,
){
    const [baseValues, setBaseValues] = useState(_baseValues)
    const [submitting, setSubmitting] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [object, setObject] = useState(_baseValues)
    const [formType, setFormType] = useState(defaultType)


    const onChange = (e, {name, value})=>{
        if (name==="Disc_saleOnBills"){
          setObject({...object, Disc_saleOnItem:false,[name]:value})
          
        }else if (name==="Disc_saleOnItem"){
          setObject({...object, Disc_saleOnBills:false, [name]:value})
        }
        else if (name==="sale_no_less_than_minPrice" ){
            if(value===true){
                setObject({...object, sale_no_less_than_minPrice:1})
            }else{
                setObject({...object, sale_no_less_than_minPrice:0})
            }
          }
          else if (name==="use_price_without_vat" ){
            if(value===true){
                setObject({...object, use_price_without_vat:1})
            }else{
                setObject({...object, use_price_without_vat:0})
            }
          }
        else{
            setObject({...object, [name]:value})
        }

    }
   
    async function submit(value){
        const data = {...baseValues, ...value}
        setSubmitting(true)
        try{
            const submitted = await submitFunction(data)
            console.log(data);
            setSubmitting(false)
            toast.success('تم الإضافة')
            onEnd()
            return submitted
        } catch (e){
            toast.error('خطاء في عملية الإضافة');
            console.log(e);
        }
        setSubmitting(false)

    }

    async function update(value){
        if (object?.allow_discount){
            if ((object.apply_discount==1 ||object.apply_discount==3)&&parseFloat( object?.SaleDiscPers)<=0){
                alert("برجاء تحديد نسبة الخصم المسموح بها");
              }else  if ((object.apply_discount==2 ||object.apply_discount==3)&&parseFloat( object?.SaleDiscValue)<=0){
                alert("برجاء تحديد مبلغ الخصم المسموح به");
              }else{
                setSubmitting(true)
                try{
        
                    const updated = await updateFunction(value)
                    setSubmitting(false)
                    toast.success('تم التعديل')
                    onEnd()
                    return updated
                } catch (e){
                    toast.error("لم يتم التعديل")
                    console.log(e);
                }
                setSubmitting(false)
              }
        }else{
            setSubmitting(true)
            try{
    
                const updated = await updateFunction(value)
                setSubmitting(false)
                toast.success('تم التعديل')
                onEnd()
                return updated
            } catch (e){
                toast.error("لم يتم التعديل")
                console.log(e);
            }
            setSubmitting(false)
        }

    }


    async function remove(id){
        setSubmitting(true)
        try{
            const removed = await removeFunction(id)
            console.log(removed.data);
            if(removed.data){
               toast.success('تم الحذف.. ')
                onEnd()
            } else {
                toast.error("لا يمكن الحذف لأن العنصر مرتبط بجداول اخرى")
            }
            setSubmitting(false)
            return removed
        } catch (e){
            console.log(e);
            toast.error(e)
        }
        setSubmitting(false)
        
    }
    
    async function getRow(value){
        setSubmitting(true)
        try{

            const ressponse = await GetRowFunction(value)
            setSubmitting(false)
            return ressponse
        } catch (e){
            toast.error("لم يتم جلب البيانات")
            console.log(e);
        }
        setSubmitting(false)
        return null;
    }
    

    function onShow(newObject ={}, type = 'new'){
        // if(get(newObject, 'constructor.name')=== 'Class') newObject ={}
        setObject(newObject)
        setFormType(type)
        onShowFunction && onShowFunction()
        
    }

    function onEnd(){
        setObject(_baseValues)
        setFormType('new');
        onEndFunction && onEndFunction()

    }

    return {
        onChange,
        submit,
        update, 
        remove,
        submitting,
        errorMessage,
        onShow,
        onEnd,
        object,
        formType,
        getRow
    }
}