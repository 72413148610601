import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { sto } from "../../../../../Context/InshData";
import { uri } from "../../../../../help/Api";
import HashLoader from "react-spinners/HashLoader";
import { colors, Container, Dialog, Modal } from "@material-ui/core";
import LoadingPage from "../../../../../services/LoadingPage";
import SearchInvoiceModal from "./SearchInvModel";
const defaultAutos = {
  Branches: [],
};

export default function SearchInvoice(props) {
  const {
    openDw,
    handCloseDw,
    transition,
    showChosedInv,
    invsShow,
    stElemnt,
    style,
    gets,
    setOr,
    or,
    optionsCustomers,
    PcIdIvPc,
    setting,
    setIsClick,
    ished,
  } = props;
  const tb = {
    wait: true,
    Branches: true,
    Users: true,
    stor: sto,
    parms: {
      user: -1,
    },
  };

  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "Rept",
    tb,
  );

  if (
    fetchAll ||
    !AutoOptions?.Branches
  )
    return <LoadingPage />;

  return (

    <SearchInvoiceModal
      {...{
        AutoOptions: AutoOptions?.Branches ? AutoOptions : defaultAutos,
        openDw,
        handCloseDw,
        transition,
        showChosedInv,
        invsShow,
        stElemnt,
        style,
        gets,
        setOr,
        or,
        optionsCustomers,
        PcIdIvPc,
        setting,
        setIsClick,
        ished,
        isPurches:false,
        isStock:false
      }}
    />
  );
}
