import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  useStylesUp, useStylesHed, useStylesAuto, useStylesAutoPurch, useStylesTF, useStylesTD, useStylesBA, useStylesLbl
  , useStylesUiElemment,
  useStylesSpDial,
} from '../../../../../St/Reports/UseStyle';
export default function SaleAnalysisForm(props) {
  const {
    rptParams,
    onChangeData,
    AutoOptions,
    CustomerOptions,
    onChangeAutoData,
    printHandle,
    branch,
    setBranch,
    isPurches
  } = props;

  const { t } = useTranslation(["forms"]);
  const clasAo = useStylesAuto();
  const calsPurch = useStylesAutoPurch();
  const claslbl = useStylesLbl();
  const clasTF = useStylesTF();
  return (
    <React.Fragment>
      <Grid container style={{ padding: 5 }} spacing={1}>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            classes={isPurches ? calsPurch : clasAo}
            value={rptParams.branch}
            onChange={(event, newValue) => {
              setBranch(newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.Branches}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrBlackLblSal }}
                label={t("branch")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            className={isPurches ? clasTF.rootPurch : clasTF.rootSale}
            InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
            InputLabelProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrSale }}
            variant="outlined"
            size="small"
            id="datef"
            label={t("date-from")}
            type="date"
            format="YYYY-MM-DD"
            name="datef"
            value={rptParams.dateFrom}
            onChange={onChangeData("dateFrom")}
          />
        </Grid>
        {rptParams.selectTime.id == 2 && (
          <Grid item lg={2} md={2} sm={3} xs={6}>
            <TextField fullWidth variant="outlined" size="small" id="timeFrom" label="الوقت "
              className={isPurches ? clasTF.rootPurch : clasTF.rootSale}
              InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
              InputLabelProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrSale }}
              type="time"
              format="HH:mm"
              name="timeFrom" value={rptParams.timeFrom} onChange={onChangeData('timeFrom')}
            /></Grid>
        )}

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            className={isPurches ? clasTF.rootPurch : clasTF.rootSale}
            InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
            InputLabelProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrSale }}
            variant="outlined"
            size="small"
            id="dateT"
            label={t("date-to")}
            type="date"
            format="YYYY-MM-DD"
            name="datet"
            value={rptParams.dateTo}
            onChange={onChangeData("dateTo")}
          />
        </Grid>
        {rptParams.selectTime.id == 2 && (
          <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
            <TextField fullWidth variant="outlined" size="small" id="timeTo" label="الوقت "
              className={isPurches ? clasTF.rootPurch : clasTF.rootSale}
              InputProps={{ className: isPurches ? claslbl.clrPurch : claslbl.clrSale }}
              InputLabelProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrSale }}
              type="time"
              format="HH:mm"
              name="timeTo" value={rptParams.timeTo} onChange={onChangeData('timeTo')}
            /></Grid>
        )}
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            classes={isPurches ? calsPurch : clasAo}
            value={rptParams.safe}
            onChange={(event, newValue) => {
              onChangeAutoData("safe", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.Safes}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrBlackLblSal }}
                label={t("safe")}
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            classes={isPurches ? calsPurch : clasAo}

            value={rptParams.cust}
            onChange={(event, newValue) => {
              onChangeAutoData("cust", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={CustomerOptions}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrBlackLblSal }}
                label={t("customer")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            classes={isPurches ? calsPurch : clasAo}
            value={rptParams.user}
            onChange={(event, newValue) => {
              onChangeAutoData("user", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.Users}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrBlackLblSal }}
                label={t("user")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            classes={isPurches ? calsPurch : clasAo}
            value={rptParams.stock}
            onChange={(event, newValue) => {
              onChangeAutoData("stock", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.Stocks}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrBlackLblSal }}
                label={t("stock")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            classes={isPurches ? calsPurch : clasAo}
            value={rptParams.salesmen}
            onChange={(event, newValue) => {
              onChangeAutoData("salesmen", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.salesmen}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrBlackLblSal }}
                label={t("salesman")}
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            classes={isPurches ? calsPurch : clasAo}
            value={rptParams.group}
            onChange={(event, newValue) => {
              onChangeAutoData("group", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.groups}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrBlackLblSal }}
                label={t("group")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  fullWidth
                  checked={rptParams.chk_withvat}
                  onChange={(e) =>
                    onChangeAutoData("chk_withvat", e.target.checked)
                  }
                  name="chkVat"
                />
              }
              size="small"
              color="primary"
              fontSize="small"
              label={t("withtax")}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
