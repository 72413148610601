import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import AccountSelect from '../../../components/controls/accounts-select';
import DeptPeriodFilter from './DeptPeriodFilter';
import {
  useStylesUp, useStylesHed, useStylesAuto,useStylesAutoPurch,useStylesTF, useStylesTD, useStylesBA, useStylesLbl
  , useStylesUiElemment,
  useStylesSpDial,
} from '../../../St/Reports/UseStyle';


export default function DeptPeriodForm(props){

  const {
    AutoOptions,
    rptParams,
    onChangeData,
    onChangeAutoData,
  }=props;

  const clasup = useStylesUp();
  const calsPurch = useStylesAutoPurch();
  const claslbl = useStylesLbl();
  const clasAo = useStylesAuto();
  const clasTF = useStylesTF();
    return (
          <React.Fragment>

              <Grid container style={{padding:5 }} spacing={1}>

                <Grid item lg={2} md={2}  sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                    <TextField  fullWidth variant="outlined" size="small" id="datef" 
                    label="من تاريخ"
                    className={clasTF.rootCust} 
                    InputProps={{ className:claslbl.clrBlack }}
                    InputLabelProps={{ className:claslbl.clrBlackLblS }}
                    type="date" format="YYYY-MM-DD"
                    name="datef" value={rptParams.dateFrom} 
                    onChange={onChangeData("dateFrom")}/>
                </Grid>

                <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                  <TextField fullWidth variant="outlined" size="small" id="dateT" 
                  label="إلى تاريخ" 
                  className={clasTF.rootCust} 
                  InputProps={{ className:claslbl.clrBlack }}
                  InputLabelProps={{ className:claslbl.clrBlackLblS }}
                  type="date" format="YYYY-MM-DD"
                  name="datet" value={rptParams.dateTo} 
                  onChange={onChangeData("dateTo")}/>
                </Grid>

                <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}> 

                    <Autocomplete 
                    
                    value={rptParams.type.id==1? rptParams.cust:rptParams.sup}
                      onChange={(event, newValue) => { 
                         onChangeAutoData("cust", newValue)  
                      }}
                      id="combo-box-demo" size="small"
                      options={rptParams.type.id==1? AutoOptions.Customers:AutoOptions.Suppliers} 
                      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                      style={{  }}
                      disableClearable
                      renderInput={(params) => <TextField {...params}  InputLabelProps={{shrink: true}} 
                      label= {rptParams.type.id==2 ? "المورد" : "العميل"}
                      variant="outlined" />}   
                    />
                </Grid>
                <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}> 
                    <Autocomplete value={rptParams.branch}
                      onChange={(event, newValue) => { 
                         onChangeAutoData("branch", newValue)  
                      }}
                      id="combo-box-demo" size="small"
                      options={AutoOptions.Branches} 
                      //getOptionLabel={(option) => option.name}
                      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                      style={{  }}
                      disableClearable
                      renderInput={(params) => <TextField {...params}  InputLabelProps={{shrink: true}} label="الفرع" variant="outlined" />}   
                    />
                </Grid>
                <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}> 
                    <Autocomplete value={rptParams.type.id==1? rptParams.act:rptParams.actsup}
                      onChange={(event, newValue) => { rptParams.type.id==1?
                         onChangeAutoData("act", newValue) : onChangeAutoData("actsup", newValue) 
                      }}
                      id="combo-box-demo" size="small"
                      options={rptParams.type.id==1? AutoOptions.ActsCust:  AutoOptions.ActsSupp} 
                      //getOptionLabel={(option) => option.name}
                      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                      style={{  }}
                      disableClearable
                      renderInput={(params) => <TextField {...params}  InputLabelProps={{shrink: true}}
                       label="المجموعة" variant="outlined" />}   
                    />
                </Grid>
                
                <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                  <TextField fullWidth variant="outlined" size="small" id="dateT" 
                  label="الفترة الأولى"
                  type="number" 
                  className={clasTF.rootCust} 
                  InputProps={{ className:claslbl.clrBlack }}
                  name="first" value={rptParams.firstPeriod} 
                  onChange={onChangeData("firstPeriod")}/>
                </Grid>
                <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                  <TextField fullWidth variant="outlined" size="small" id="dateT" 
                  label="الفترة الثانية"
                  type="number" 
                  className={clasTF.rootCust} 
                  InputProps={{ className:claslbl.clrBlack }}
                  name="second" value={rptParams.secondPeriod} 
                  onChange={onChangeData("secondPeriod")}/>
                </Grid>
                <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                  <TextField fullWidth variant="outlined" size="small" id="dateT" 
                  label="الفترة الثالثة"
                  type="number" 
                  className={clasTF.rootCust} 
                  InputProps={{ className:claslbl.clrBlack }}
                  name="thied" value={rptParams.thirdPeriod} 
                  onChange={onChangeData("thirdPeriod")}/>
                </Grid>
                <Grid item lg={2} md={2} sm={4} xs={6} style={{fontWeight:"bold" ,paddingBottom:"0.5rem"}}>
                  <TextField fullWidth variant="outlined" size="small" id="dateT" 
                  label="الفترة الرابعة"
                  type="number" 
                  className={clasTF.rootCust} 
                  InputProps={{ className:claslbl.clrBlack }}
                  name="fourth" value={rptParams.fourthPeriod} 
                  onChange={onChangeData("fourthPeriod")}/>
                </Grid>
              

 
            </Grid>  

          </React.Fragment>
    );
 
}