const txtA = {
  payType: "",
  shamel: "",
  priceI:"السعر",
  found: " المؤسسة",
  invNumr: "رقم الفاتورة",
  taxNumr: "الرقم الضريبي",
  taxphone: "رقم الجوال",
  invDate: "تاريخ الفاتورة",
  time: "الوقت",
  cusNM: "المورد",
  cusNA: "العميل",
  cusSQK: "استلمت من السيد",
  cusSQD: " دفعة من حساب",
  cusSSD: " دفعة لحساب",
  cusSSK: "دفعة لحساب",
  accSQK: "يودع في حساب",
  accSSK: "يصرف من حساب",
  user: "كاشير",
  custmName: "إسم العميل",
  custAddress: "بيانات العميل",
  invRef: "رقم المرجع",
  /* table AF */
  itemName: "اسم الصنف",
  unitPrice: "سعر الوحدة",
  namePriceSal: "سعر البيع",
  namePricePurch: "سعر الشراء",
  nameTotSal: "إجمالي المبيعات",
  nameTotPurch: "إجمالي المشتريات",
  Quantity: "الكمية",
  taxableAmount: "المبلغ الخاضع للضريبة",
  discount: "الخصم",
  taxRate: "نسبة الضريبة",
  taxAmount: "مبلغ الضريبة",
  subTotalWithVat: "المجموع شامل الضريبة",
  totalAmount: "اجمالي المبالغ",

  /* table SandSarf */
  accountCode: "كود الحساب",
  accountName: "إسم الحساب",
  price: "القيمة",
  returnNumbr: "رقم المرجع",
  notes: "ملاحظات",
  saleVat: "المبيعات",
  purchVat: "المشتريات",
  /* statics */
  titleFatora: "فاتورة ضريبية مبسطه",

  amountWithVat: "الإجمالي(غير شامل الضريبة القيمة المضافة)",
  totdiscount: "مجموع الخصومات",
  amountWithVat2: "الإجمالي الخاضع للضريبة(غير شامل ضريبة القيمة المضافة)",
  amountOfVat: "(15%)مجموع ضريبة القيمة المضافة",
  tot_net: "إجمالي المبلغ المستحق",
  invName: {
    safe: "مـخــزون أول الـمـدة",
    sale: "فـاتـورة مـبـيـعـات",
    saleRet: "مـرتـجـع مـبـيـعـات",
    saleViw: "عـرض سـعر",
    purch: "فـاتـورة مـشـتـريـات",
    purchRet: "مـرتـجـع مـشـتـريـات",
    purchOrder: "أمر شراء",
  },
  bascData: {
    Foundations: "بـيـانـات الـمؤسـسـة",
    Foundation_points: "تـفـاصـيـل رأس وتـذيـيـل الـمـؤسـسـة",
    // found:'تعريف المؤسسة',
    branch: "تـعـريـف الـفـرع",
    Branches: "تـعـريـف الـفـروع",
    unit: "تـعـريـف الـوحـدات",
    items: "تـعـريـف الاصـنـاف",
    groups: "تـعـريـف مجموعات",
    // groups:"الـوظـائـف",
    // groups:'مـجـمـوعـات الاصـنـاف',
    tax_groups: "تـعـريـف الـمـجـمـوعـات الـضـريـبـيـة",
    // groupsTax:"المجموعات الضريبية",
    // safes:"تـعـريـف الاصـنـاف",
    Safes: "تـعـريـف الـمـخـازن",
    // stocks:"تـعـريـف الاصـنـاف",
    Stocks: "تـعـريـف الـصـنـاديـق",
    // banks:"تـعـريـف الاصـنـاف",
    Banks: "تـعـريـف الـبـنـوك",
    costcenter: "مـراكـز الـتـكـلـفـة",
    omlat: "تـعـريـف الـعـمـلات",
    // omlat:'',
    AccountBooks: "الـدفـاتـر الـمـحـاسـبـيـة",
    Acts: "مـجـمـوعـات الـعـمـلاء والـمـورديـن",
    Countries: "تـعـريـف الـدول",
    Cities: "تـعـريـف الـمـدن",
    Areas: "تـعـريـف الـمـنـاطـق",
    Managements: "تـعـريـف الإدارات",
    Departments: "الأقـسـام",
    jobs: "تـعـريـف الـوظـائـف",
    CardTypes: "تـعـريـف بـطـائـق الـدفـع",
  },
  customers: {
    arsedah: "ارصدة حسابات العملاء",
  },
  Accounts: {
    sandQK: "سـنـد قـبـض عـمـلـيـات خـارجـيـة",
    sandSK: "سـنـد صـرف عـمـلـيـات خـارجـيـة",
    sandQD: "سـنـد قـبـض عـمـلـيـات داخلية",
    sandSD: "سـنـد صرف عـمـلـيـات داخلية",
  },
  endemam: {
    TF: ["معرف جهاز النظام", "رقم الهاتف"],
  },
  rpt: [
    {
      t: ["رقم سند ", "تاريخ السند", "رقم المرجع", "مركز التكلفة"],
      i: ["استلمت من السيد", "مبلغ وقدره", "بيانه كما يلي"],
      c: ["نقدا / شيك", "رقم الشيك", "تاريخ الشيك", "دفعة محصلة من حساب"],
      d: [
        "محرر السند",
        "المستلم",
        "المحاسب",
        "المراجع",
        "المدير المالي",
        "المدير العام",
      ],
    },
    {
      t: ["Bond No", "Bond Date", "Reference No", "Cost Center"],
      c: [
        "Cash / Check",
        "Check No",
        "Check Date",
        "Paid received from account",
      ],
    },
  ],

  auto: {
    group: "مجموعات ",
    main: "رئيسية",
    sub: "فرعية",
  },
};

const txtE = {
  payType: "",
  shamel: "",
  invNumr: "Invoice Number",
  taxNumr: "VAT NO",
  // invDate:'Invoice Lssue Date',
  invDate: "Invoice Date",
  invRef: "Ref No",
  time: "Time",
  custm: "",
  user: "",
  custmName: "Client Name",
  custAddress: "Clinet data",
  invName: {
    safe: "Beginning Inventory Invoice",
    sale: "Sales Invoice",
    saleRet: "Sales Returns",
    saleViw: "Offer Price",
    purch: "Purchases Invoice",
    purchRet: "Purchases Returns",
    purchOrder: "Purchases Order",
  },
  /* table AF */
  itemName: "item name",
  unitPrice: "unit price",
  Quantity: "Quantity",
  taxableAmount: "Taxable Amount",
  discount: "Discount",
  taxRate: "Tax Rate",
  taxAmount: "Tax Amount",
  subTotalWithVat: "Sub Total With Vat",
  totalAmount: "Total Amount",

  /* statics */
  amountWithVat: "Total(Excluding VAT)",
  totdiscount: "Discount",
  amountWithVat2: "Total Taxable Amount(Excluding VAT)",
  amountOfVat: "Total VAT (15%)",
  tot_net: "Total Amount Due",
};

// const msgA = {
//    conthttps:
//    ' قم بكتابة '+' الـ ip '+' بشكل صحيح '
//    +
//    ' او '
//    +
//    ' أنقر على زر السماح لتشغيل السماحية ',

//    conthttp:'تأكد من كتابة رابط السيرفر بشكل صحيح',
//    body:{
//       cont:' قم بكتابة '+' الـ ip '+' بشكل صحيح '
//       +
//       ' او '
//       +
//       ' أنقر على زر السماح لتشغيل السماحية ',
//       enterIp:'قم بإدخال الـ ip',

//    }
// }

const msgA = {
  endemam: {
    cont: [
      " أنقر على زر السماح لتشغيل السماحية " +
        " او " +
        " قم بكتابة معرف جهاز النظام صحيح ",
      "تأكد من كتابة رابط السيرفر بشكل صحيح",

      "قم بإدخال الـ ",
      "تأكد من كتابة الرقم بشكل صحيح",
      "لا يتجاوز طول رقم المعرف اكثر من 3 ارقام",
    ],
    // ' أنقر على زر السماح لتشغيل السماحية '
    // +
    // ' او '
    // +
    // ' قم بكتابة معرف جهاز النظام صحيح ',

    // cont:'تأكد من كتابة رابط السيرفر بشكل صحيح',

    body: {
      cont: "",
    },
  },
};

// const msgE = {
// }
// let tras = 'a';

// const txtA = Object

// if (tras === 'a') { alert('sdsd'); txtA = txtAA;}

// const txtE = txtEE;
const tEnsh = {
  endemam: { TF: ["", ""] },
};
export { txtA, txtE, msgA, tEnsh };
