import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Button, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AccountSelect from '../../../components/controls/accounts-select';
import SelectTypesFilter from './balanceCustRPT-typeFilter';
import { docTypes } from '../../Accounts/BackEnd/AccountingRPT/accountingRPT-services';
import { payTypes } from './balanceCustRPT-services';
import { useStylesAuto, useStylesAutoBlack, useStylesAutoPurch, useStylesLbl, useStylesTF } from '../../../St/Reports/UseStyle';

export default function BalanceCustRPTForm(props) {

  const [account, setAccount] = useState({})
  const {
    rptParams,
    setRptParams,
    onChangeData,
    AutoOptions,
    onChangeAutoData,
    printHandle } = props;
  const accounts = rptParams.type.code == 0 ? AutoOptions.Accindex
    : AutoOptions.Accindex?.filter(ac => ac.id.toString().toLowerCase().startsWith(rptParams.type.code.toString()) || ac.id == -1);

  const calsPurch = useStylesAutoPurch();
  const calsBlack = useStylesAutoBlack();

  const claslbl = useStylesLbl();
  const clasAo = useStylesAuto();
  const clasTF = useStylesTF();
  return (
    <React.Fragment>
      <SelectTypesFilter {...{ rptParams, setRptParams, defaultAccount: accounts[0] }} />
      <Grid container style={{ padding: 5 }} spacing={1}>
        <Grid item lg={4} md={6} sm={10} xs={12}>
          <AccountSelect
            accounts={accounts}
            account={rptParams.account}
            onSelect={(newValue) => { onChangeAutoData("account", newValue) }}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={3} xs={6}>
          <TextField fullWidth variant="outlined" size="small" id="datef"
            className={clasTF.rootCust}
            InputProps={{ className: claslbl.clrBlack }}
            InputLabelProps={{ className: claslbl.clrBlack }}
            label="من تاريخ"
            type="date" format="YYYY-MM-DD"
            name="datef" value={rptParams.dateFrom}
            onChange={onChangeData("dateFrom")} />
        </Grid>

        <Grid item lg={2} md={2} sm={3} xs={6}>
          <TextField fullWidth variant="outlined" size="small" id="dateT"
            className={clasTF.rootCust}
            InputProps={{ className: claslbl.clrBlack }}
            InputLabelProps={{ className: claslbl.clrBlack }}
            label="إلى تاريخ"
            type="date" format="YYYY-MM-DD"
            name="datet" value={rptParams.dateTo}
            onChange={onChangeData("dateTo")} />
        </Grid>
        
        <Grid item lg={2} md={2} sm={3} xs={6}>
          <Autocomplete value={rptParams.branch}
            classes={calsBlack}
            onChange={(event, newValue) => {
              onChangeAutoData("branch", newValue)
            }}
            id="combo-box-demo" size="small"
            options={AutoOptions.Branches}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            style={{}}
            disableClearable
            renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true,className:claslbl.clrBlack }} 
            label="الفرع" variant="outlined" />}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={3} xs={6}>
          <Autocomplete value={rptParams.center}
            classes={calsBlack}
            onChange={(event, newValue) => {
              onChangeAutoData("center", newValue)
            }}
            id="combo-box-demo" size="small"
            options={AutoOptions.center}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            style={{}}
            disableClearable
            renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true,className:claslbl.clrBlack }} label="مركز التكلفة" variant="outlined" />}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={3} xs={6}>
          <Autocomplete value={rptParams.user}
            classes={calsBlack}
            onChange={(event, newValue) => {
              onChangeAutoData("user", newValue)
            }}
            id="combo-box-demo" size="small"
            options={AutoOptions.Users}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            style={{}}
            disableClearable
            renderInput={(params) => <TextField {...params}
            label="المستخدم " variant="outlined" size='small'
            InputLabelProps={{ shrink: true,className:claslbl.clrBlack }} />}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={3} xs={6}>
          <Autocomplete value={rptParams.payType}
            classes={calsBlack}
            onChange={(event, newValue) => {
              onChangeAutoData("payType", newValue)
            }}
            id="combo-box-demo" size="small"
            options={payTypes}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            style={{}}
            disableClearable
            renderInput={(params) => <TextField {...params}  
            label="النوع " variant="outlined" size='small'
            InputLabelProps={{className:claslbl.clrBlack , shrink: true }}  />}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox fullWidth checked={rptParams.openBalance}
                onChange={(e) => onChangeAutoData("openBalance", e.target.checked)}
                name="chkVat" />}
              size="small"
              className={claslbl.clrBlack }
              color="primary"
              fontSize="small"
              label="رصيد افتتاحي"
            />
          </FormGroup>

        </Grid>
        <Grid item lg={1} md={2} sm={2} xs={6}>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox fullWidth checked={rptParams.oldBalance}
                onChange={(e) => onChangeAutoData("oldBalance", e.target.checked)}
                name="chkVat" />}
              size="small"
              color="primary"
              fontSize="small"
              className={claslbl.clrBlack }
              label="رصيد سابق"
            />
          </FormGroup>
        </Grid>


      </Grid>

    </React.Fragment>
  );

}