import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Zoom from "@material-ui/core/Zoom";
import SearchIcon from "@material-ui/icons/Search";
import MenuItem from "@material-ui/core/MenuItem";
import { colors, Container, TextField } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {
    Autocomplete,
    ToggleButton,
    ToggleButtonGroup,
} from "@material-ui/lab";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import {
    useStylesIL,
    useStylesTA,
    useStylesInfoTcu,
    useStylesDSM,
    useStylesDTa,
    useStylesAuto,
    useStylesTF,
    useStylesTS,
    useStylesInfoGridItem,
    useStylesDTaCont,
} from "../../../../St/comps/UseStyle";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import { Mtb } from "../../../../St/comps/vstyle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import AddIcon from "@material-ui/icons/Add";
import { txtA } from "../../../../Label/Lbl";
import ItemsTable from "./items-table";
import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ItemsCard from "./items-card";
import ItemInfoDialog from "./item-info-modal";
import {
    useStylesAutoPurch,
    useStylesSpDial,
    useStylesLbl,
} from '../../../../St/Reports/UseStyle';
import {
    useStylesUiElemment,
} from "../../../../St/comps/UseStyle";
import { sto } from "../../../../Context/InshData";
import ItemsInvTable from "./itemsInvTable";
import ItemsCardList from "./ItemsCardsList";
import Search from "@material-ui/icons/Search";
export default function InvoiceItemsModals(props) {
    const [txt, setTxt] = React.useState(txtA);
    const [loading, setLoading] = useState(false);
    const [infoOpen, setInfoOpen] = useState(false);

    const onGroupChangeHandle = (prop) => async (e) => {
        setLoading(true);
        await changAutoss(e.target.value, prop);
        setLoading(false);
    };
    const {
        openCuD,
        handleCloseCuD,
        transition,
        switchState,
        groups,
        groupsSub,
        changSlides,
        changAutoss,
        subInfo,
        cuRows,
        permiCuCo,
        setting,
        changAutoPricePay,
        valuePricePay,
        changAutoCurrUnits,
        changInfo,
        optionsPricePay,
        optionsCurrUnits,
        checked,
        changSwitch,
        _onDetected,
        changChcked,
        getrowDataCurrencie,
        stElemnt,
        counts,
        permistionInv,
        rptParams,
        onChangeSearchD,
        AutoOptions,
        isPurches,
        onChangeData,
        onChangeDataA,
        onChangeAutoDataG,
        groupsSubs,
        onChangeAutoDataSub,
        onRowClicks,
        viewHandle,
        groupsOptions,
        handleOpenItems,
        isStock,
        valueItemUnits,
        valueCurrUnits,
    } = props;

    const { t } = useTranslation(["forms"]);

    const locCurr = useMemo(
        () => JSON.parse(localStorage.getItem("premiApp")) || {}
    );
    const clasui = useStylesUiElemment();

    const clasSM = useStylesDSM();
    const clasTa = useStylesDTa();
    const clasAo = useStylesAuto();
    const clasTF = useStylesTF();
    const clasTA = useStylesTA();
    const clasTS = useStylesTS();
    const claslbl = useStylesLbl();
    const claslbls = useStylesLbl();

    const clasGrdI = useStylesInfoGridItem();
    const classesInfo = useStylesInfoTcu();
    const clasTaCont = useStylesDTaCont();

    const [viewType, setViewType] = useState(locCurr.showCurr ? "card" : "table");
    const [sum, setSum] = useState(false);


    return (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Dialog
                fullScreen
                className={clasTa.rootTa}
                open={openCuD}
                transition={Zoom}
                TransitionComponent={transition}
                key={transition ? transition.name : ""}
                transitionDuration={250}
                onClose={handleCloseCuD}
            >
                {!switchState.tableOrscanBC ? (
                    <Fragment>
                        {locCurr.statu ? (
                            <DialogTitle
                                className={clasSM.dialogTitleSM}
                                id="customized-dialog-title"
                            >
                                {/* <IconButton aria-label="close" className={clasSM.closeButtonSM} onClick={handleCloseSM} > <CloseIcon /> </IconButton> */}
                                <Tabs
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    {groups.map((group, i) => (
                                        <Tab
                                            key={i}
                                            label={group.name}
                                            onClick={() => changSlides(group.group_id, "Gmain")}
                                        />
                                    ))}
                                </Tabs>
                                <Tabs
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    {groupsSub.map((groupSub, i) => (
                                        <Tab
                                            key={i}
                                            label={groupSub.name}
                                            onClick={() => changSlides(groupSub.group_id, "GSub")}
                                        />
                                    ))}
                                </Tabs>
                            </DialogTitle>
                        ) : (
                            <DialogTitle
                                className={clasTa.dialogTitleTa}
                                id="scroll-dialog-title"
                            >
                                {/* قم بإختيار صنف */}
                                {/* <Grid item container>
                                    <Grid item></Grid>
                                </Grid> */}
                                <Grid
                                    item
                                    container
                                    style={{
                                        flex: 1,
                                        justifyContent: "space-evenly",
                                        direction: "rtl",
                                    }}
                                >
                                        
                                  
                                    <Grid item lg={1} md={1} sm={1} xs={2} >
                                        <Container
                                            maxWidth={false}
                                            style={{
                                                padding: "5px",
                                            }}
                                        >
                                            <IconButton
                                                edge="start"
                                                color="inherit"
                                                aria-label="open drawer"
                                                className={isPurches ? [clasui.buttonPurch] :isStock?[clasui.buttonSafes]: [clasui.buttonSal]}
                                                onClick={() => viewHandle()}
                                            >
                                                <Search />
                                            </IconButton>
                                        </Container>
                                    </Grid>

                                    <Grid item lg={2} md={2} sm={2} xs={5} >
                                        <TextField
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            id="id"
                                            label={t("forms:barcode")}
                                            style={{ backgroundColor:isPurches?colors.blue[50] :isStock?colors.blue[50]: colors.green[50], marginBlock: 5}}
                                            name="barcodeS"
                                            value={rptParams.barcodeS}
                                            InputProps={{
                                                onKeyDown: (event) => {
                                                    if (event.key === 'Enter') {
                                                       onChangeSearchD(event);
                                                        // viewHandle();
                                                    }
                                                },
                                            }}
                                            onChange={onChangeData("barcodeS")} />
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={3} xs={5} >
                                        <TextField
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            id="symbol"
                                            label={"ابحث عن الصنف"}
                                            style={{ backgroundColor:isPurches?colors.blue[50] :isStock?colors.blue[50]: colors.green[50], marginBlock: 5}}
                                            name="symbol"
                                            value={rptParams.symbol}
                                            InputProps={{
                                                onKeyDown: (event) => {
                                                    if (event.key === 'Enter') {
                                                        viewHandle();
                                                    }
                                                },
                                            }}
                                            onChange={onChangeDataA("symbol")}
                                        />
                                    </Grid>

                                    <Hidden smUp={["sm"]}>
                                        <Grid item lg={2} md={2} sm={4} xs={8} >
                                            <Autocomplete
                                                value={rptParams.group}
                                                labelPlacement=""
                                                onChange={(event, newValue) => {
                                                    onChangeAutoDataSub("group", newValue)
                                                }}
                                                id="controllable-states-demo"
                                                size="small"
                                                style={{ backgroundColor:isPurches?colors.blue[50] :isStock?colors.blue[50]: colors.green[50], marginBlock: 5}}
                                                options={groupsOptions}
                                                disableClearable
                                                getOptionLabel={(option) =>
                                                    typeof option === "string" ? option : option.name
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={"مجموعة الصنف"}
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Hidden>

                                    <Hidden only={["xs"]}>
                                        <Grid item lg={3} md={3} sm={3} xs={8} >
                                            <Autocomplete
                                                value={rptParams.group}
                                                labelPlacement=""
                                                onChange={(event, newValue) => {
                                                    onChangeAutoDataG("group", newValue)
                                                }}
                                                id="controllable-states-demo"
                                                size="small"
                                                style={{ backgroundColor:isPurches?colors.blue[50] :isStock?colors.blue[50]: colors.green[50], marginBlock: 5}}
                                                disableClearable
                                                options={groupsOptions}
                                                
                                                getOptionLabel={(option) =>
                                                    typeof option === "string" ? option : option.name
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={"المجموعة الرئيسية"}
                                                        variant="outlined"
                                                        size="small"
                                                        
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={2} sm={2} xs={6} >
                                            <Autocomplete
                                                value={rptParams.groupS}
                                                labelPlacement=""
                                                onChange={(event, newValue) => {
                                                    onChangeAutoDataSub("groupS", newValue)
                                                }}
                                                id="controllable-states-demo"
                                                size="small"
                                                style={{ backgroundColor:isPurches?colors.blue[50] :isStock?colors.blue[50]: colors.green[50], marginBlock: 5}}
                                                options={groupsSubs}
                                                disableClearable
                                                getOptionLabel={(option) =>
                                                    typeof option === "string" ? option : option.name
                                                }
                                                renderInput={(params) => (
                                                    
                                                    <TextField
                                                        {...params}
                                                        label={"المجموعة الفرعية"}
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Hidden>
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={viewType}
                                        exclusive
                                        style={{ direction: "ltr" }}
                                        onChange={(event, nextView) => {
                                            if (nextView != null) setViewType(nextView);
                                        }}
                                        aria-label="Platform"
                                    >
                                        <ToggleButton value="card" aria-label="card">
                                            <ViewModuleIcon />
                                        </ToggleButton>
                                        <ToggleButton value="table" aria-label="table">
                                            <ViewListIcon />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                    <Hidden smDown={["sm"]}>
                                        <Grid item lg={6} md={4}></Grid>
                                    </Hidden>
                                </Grid>
                            </DialogTitle>
                        )}
                    </Fragment>
                ) : (
                    <Fragment>
                        <DialogTitle
                            className={clasSM.dialogTitleSM}
                            id="customized-dialog-title"
                        >
                            ماسح باركود
                        </DialogTitle>
                    </Fragment>
                )}

                <DialogContent className={clasTa.dialogContentTa} dividers>
                    <Grid
                        item
                        container
            /* lg={12} md={12} sm={12} xs={12} */ className={
                            clasTaCont.rootTaCont
                        }
                    >
                        {!switchState.tableOrscanBC ? (
                            <Fragment>
                                {viewType == "card" ? (
                                    <Grid
                                        item
                                        lg={6}
                                        md={7}
                                        sm={12}
                                        xs={12}
                                        className={classesInfo.gridTable}
                                    >
                                        <ItemsCardList
                                            {...{
                                                loading,
                                                cuRows,
                                                AutoOptions,
                                                onRowClicks,
                                                isPurches,
                                                isStock
                                            }}
                                        />
                                    </Grid>
                                ) : (
                                    <Grid
                                        item
                                        lg={6}
                                        md={8}
                                        sm={12}
                                        xs={12}
                                        className={classesInfo.gridTable}
                                    >
                                        <ItemsInvTable
                                            {...{
                                                classesInfo,
                                                loading,
                                                cuRows,
                                                permiCuCo,
                                                setting,
                                                Mtb,
                                                stElemnt,
                                                AutoOptions,
                                                onRowClicks,
                                                isPurches,
                                                isStock
                                            }}
                                        />
                                    </Grid>
                                )}
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Grid item lg={6} md={8} sm={12} xs={12}>
                                    <BarcodeScannerComponent
                                        // className="video"
                                        width={300}
                                        facingMode="environment"
                                        // delay
                                        height={300}
                                        onUpdate={(err, result) => {
                                            if (result) _onDetected(result.text);
                                        }}
                                    />
                                    {/* </div> */}
                                </Grid>
                            </Fragment>
                        )}

                        <Grid
                            item
                            lg={6}
                            md={4}
                            sm={12}
                            xs={12}
                            className={classesInfo.gridInfo}
                        >
                            <Grid item className={classesInfo.gridInfoInner}>
                                <Grid item container spacing={1}>
                                    <Grid item lg={7} md={12} sm={7} xs={8}>
                                        <TextField
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            id="symbol"
                                            label={t("item-name")}
                                            className={clasTF.rootI}
                                            InputProps={{ className: clasTF.input, readOnly: true }}
                                            name="symbol"
                                            value={subInfo.symbol}
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={7} sm={3} xs={4}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            id="barcode"
                                            label={t("barcode")}
                                            className={clasTF.rootI}
                                            InputProps={{ className: clasTF.input, readOnly: true }}
                                            name="barcode"
                                            value={subInfo.barcode}
                                        />
                                    </Grid>

                                    <Grid item xs={12} className={clasGrdI.rootIGI}></Grid>

                                    <Grid item lg={5} md={4} sm={4} xs={5}>
                                        <Autocomplete
                                            classes={clasAo}
                                            value={valueItemUnits}
                                            onChange={(event, newValue) => {
                                                changAutoCurrUnits(event, newValue);
                                            }}
                                            id="controllable-states-dem"
                                            size="small"
                                            options={optionsCurrUnits}
                                            getOptionLabel={(option) =>
                                                typeof option === "string" ? option : option.unitName
                                            }
                                            style={{}}
                                            disableClearable
                                            renderInput={(params) => (
                                                <TextField
                                                    id="valueCurrUnits"
                                                    {...params}
                                                    label={t("unit")}
                                                    variant="outlined"
                                                    size="small"
                                                    InputLabelProps={{ className: claslbl.clor }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item lg={5} md={3} sm={4} xs={4}>
                                        <Autocomplete
                                            classes={clasAo}
                                            value={valuePricePay}
                                            onChange={(event, newValue) => {
                                                changAutoPricePay(newValue);
                                            }}
                                            id="controllable-states-dem"
                                            size="small"
                                            options={optionsPricePay}
                                            getOptionLabel={(option) =>
                                                typeof option === "string" ? option : option.name
                                            }
                                            style={{}}
                                            // disabled={statu Dis.pricePay}
                                            disabled={setting.cusType == 2?true:permiCuCo.DTSP}
                                            disableClearable
                                            renderInput={(params) => (
                                                <TextField
                                                    id="valuePricePay"
                                                    {...params}
                                                    label={setting.priceNM}
                                                    variant="outlined"
                                                    size="small"
                                                    InputLabelProps={{ className: claslbl.clor }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={5} sm={2} xs={3}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            id="perc"
                                            label={t("packaging")}
                                            className={clasTF.root}
                                            InputProps={{ className: clasTF.input, readOnly: true }}
                                            InputLabelProps={{ shrink: true }}
                                            name="perc"
                                            value={subInfo.perc}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={3} sm={2} xs={3}>
                                        <TextField
                                            type="number"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            id="val1"
                                            label={t("quanity")}
                                            className={clasTF.root}
                                            InputProps={{ className: clasTF.input }}
                                            name="val1"
                                            InputLabelProps={{ shrink: true }}
                                            value={subInfo.val1}
                                            onChange={changInfo("val1")}
                                        />
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={2} xs={4}>
                                        <TextField
                                            type="number"
                                            fullWidth
                                            disabled={!permistionInv.USP}
                                            variant="outlined"
                                            size="small"
                                            id="Price"
                                            label={t("price")}
                                            className={clasTF.root}
                                            InputProps={{ className: clasTF.input }}
                                            name="Price"
                                            pattern="[0-9]*"
                                            value={subInfo.Price}
                                            onChange={changInfo("Price")}
                                        />
                                    </Grid>

                                    <Grid item lg={6} md={5} sm={4} xs={5}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            id="totJust"
                                            label={t("total")}
                                            disabled={!permistionInv.USP}
                                            className={clasTF.root}
                                            InputProps={{ className: clasTF.input, readOnly: false }}
                                            name="totJust"
                                            value={subInfo.totJust}
                                            onChange={changInfo("totJust")}
                                        />
                                    </Grid>

                                    {/* <div>{''+permiElm.APDC+' - '+permiElm.APDC+' - '+permiElm.DSOI}</div> */}
                                    {/* <div>{''+permiElm.APDC+' - '+permiElm.APDC+' - '+permiElm.DSOI}</div> */}
                                    <Grid item lg={1} md={1} sm={1} xs={2}>
                                        {" "}
                                        <Button
                                            disabled={subInfo.barcode == ""}
                                            style={{ textColor: "#000" }}
                                            onClick={() => {
                                                setInfoOpen(true);
                                            }}
                                        >
                                            {"?"}
                                        </Button>
                                    </Grid>
                                    <Grid item lg={2} md={3} sm={2} xs={3}>
                                        <TextField
                                            type="number"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            id="val1"
                                            label={t("inventory-balance")}
                                            className={clasTF.root}
                                            disabled={true}
                                            InputProps={{ className: clasTF.input }}
                                            name="rasid"
                                            InputLabelProps={{ shrink: true }}
                                            value={counts.curr}
                                        />
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={4} xs={4}>
                                        <TextField
                                            type="number"
                                            fullWidth
                                            disabled={
                                                (permistionInv.APDC === 2 || permistionInv.APDC === 3) &&
                                                permistionInv.DSOI == 1
                                                    ? false
                                                    : true
                                            }
                                            variant="outlined"
                                            size="small"
                                            id="discount"
                                            label={t("discount-amount")}
                                            className={clasTF.root}
                                            InputProps={{ className: clasTF.input }}
                                            name="discount"
                                            placeholder={"0"}
                                            InputLabelProps={{ shrink: true }}
                                            value={subInfo.discount}
                                            onChange={changInfo("discount")}
                                        />
                                    </Grid>

                                    <Grid item lg={5} md={4} sm={4} xs={3}>
                                        <TextField
                                            type="number"
                                            fullWidth
                                            disabled={
                                                (permistionInv.APDC === 1 || permistionInv.APDC === 3) &&
                                                    permistionInv.DSOI == 1
                                                    ? false
                                                    : true
                                            }
                                            variant="outlined"
                                            size="small"
                                            id="DiscPers"
                                            label={t("discount") + "%"}
                                            className={clasTF.root}
                                            InputProps={{ className: clasTF.input }}
                                            name="DiscPers"
                                            placeholder={"0"}
                                            InputLabelProps={{ shrink: true }}
                                            value={subInfo.DiscPers}
                                            onChange={changInfo("DiscPers")}
                                        />
                                    </Grid>

                                    <Grid item xs={12} className={clasGrdI.rootIGI}>
                                        <FormLabel className={claslbl.clor}>
                                            {t("value-tax")}
                                        </FormLabel>
                                    </Grid>

                                    <Grid item lg={3} md={6} sm={3} xs={3}>
                                        <TextField
                                            type="text"
                                            fullWidth
                                            disabled={!permistionInv.SVU}
                                            variant="outlined"
                                            size="small"
                                            id="taxperc"
                                            label="%"
                                            className={clasTF.root}
                                            InputProps={{ className: clasTF.input }}
                                            name="taxperc"
                                            placeholder={"0"}
                                            InputLabelProps={{ shrink: true }}
                                            value={subInfo.taxperc}
                                            onChange={changInfo("taxperc")}
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} sm={3} xs={5}>
                                        <TextField
                                            type="text"
                                            fullWidth
                                            disabled
                                            variant="outlined"
                                            size="small"
                                            id="taxval"
                                            label={t("value")}
                                            className={clasTF.root}
                                            InputProps={{ className: clasTF.input }}
                                            name="taxval"
                                            placeholder={"0"}
                                            InputLabelProps={{ shrink: true }}
                                            value={subInfo.taxval}
                                            onChange={changInfo("taxval")}
                                        />
                                    </Grid>

                                    <Grid item lg={6} md={12} sm={6} xs={4}>
                                        <Button
                                            fullWidth
                                            autoFocus
                                            variant="contained"
                                            color="primary"
                                            onClick={getrowDataCurrencie}
                                            style={{
                                                background:isPurches?"#008080":isStock?"#3f51b5": colors.green[500],
                                                ...{
                                                    paddingLeft: 0,
                                                    paddingRight: 0,
                                                },
                                            }}
                                            startIcon={<ArrowDownwardIcon />}
                                        >
                                            {" "}
                                            {t("item-download")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* )} */}
                    </Grid>
                </DialogContent>

                <DialogActions className={clasTa.dialogActionsTa}>
                    <Grid
                        item
                        lg={1}
                        md={1}
                        sm={1}
                        xs={2}
                        style={{
                            padding: "0px",
                            color: "#fff",
                            textAlign: "-webkit-center",
                        }}
                    >
                        <IconButton
                            color="inherit"
                            style={{
                               // background: "#b925bcd4",
                                padding: "10px",
                                color: "#fff",
                            }}
                            className={isPurches ? [clasui.buttonPurch] :isStock?[clasui.buttonSafes]: [clasui.buttonSal]}
                            aria-label="open drawer"
                            onClick={handleOpenItems}
                        >
                            <AddIcon />
                        </IconButton>
                    </Grid>

                    <Tooltip title="أوتوماتيك">
                        <FormControlLabel
                            id="autoOrStaticck"
                            value="autoOrStaticck"
                            name="autoOrStaticck"
                            control={
                                <Checkbox
                                    checked={checked.autoOrStaticck}
                                    onChange={changChcked}
                                    size=""
                                    color="primary"
                                />
                            }
                        // label="أوتوماتيك" labelPlacement="start"
                        />
                    </Tooltip>

                    <Tooltip title="البقاء في شاشة الأصناف">
                        <FormControlLabel
                            style={{ fontSize: "0.5rem" }}
                            control={
                                <Switch
                                    checked={switchState.stayInDCurr}
                                    onChange={changSwitch("stayInDCurr")}
                                    id="stayInDCurr"
                                    value="stayInDCurr"
                                    name="stayInDCurr"
                                    label="kdkdk"
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                />
                            }
                        //label="البقاء في شاشة الأصناف" labelPlacement="start"
                        />
                    </Tooltip>

                    <Button onClick={handleCloseCuD} color="primary">
                        {t("close")}
                    </Button>
                    {/* variant="contained" style={{backgroundColor:"#293b9e", marginRight:'10%'}} */}
                </DialogActions>
            </Dialog>
            <ItemInfoDialog
                infoOpen={infoOpen}
                setInfoOpen={setInfoOpen}
                barcode={subInfo.barcode}
                itemName={subInfo.symbol}
            />
        </Grid>
    );
}
