import React from "react";
import { uri } from "../../../help/Api";
import useFetchObjectByPost from "../../../components/Hooks/useFetchObjectByPost";
import { useEffect } from "react";
import BackdropScI from "../../../services/BackdropScI";
import Customers from "./customers";
import useFetchURL from "../../../components/Hooks/useFetchURL";
import {URL} from '../../../api/customer';
import { useLocation } from "react-router-dom";
import { sto } from "../../../Context/InshData";
export default function CustomersCon(){

     const location = useLocation();
     const type =location.state.setting.type;
    const tb = {wait:true,
        Areas:true,
        Cities:true, Countries:true, 
        salesmen: true,
        ActsCust: true,
        PermissionsElemnts:true,
        defaultSalesUser:true,
        // params
        parms:{
        iempid: sto.empi,
        branch:sto.branchi,
        iuserid:sto.idi,
        iformid:20001,
        icustmtype:location.state.setting.type,
        // iformidCuCo:dataF[3].id, /* for curr and yashml */
        },
        
        autos:{Areas:true, Cities:true, Countries:true, salesmens: true,}
    }
    const url = uri+'Res';

    const {object:data, fetching: fetchinData, fetch} =  useFetchObjectByPost(url, tb);
    const {data: newAccount, fetching, fetchURL} = useFetchURL({...{
        url: URL+`/newAccount?typeAccount=${type}`
    }});

    const tb2 = {

        defaultSalesUser:true,
        parms:{
            iempid: sto.empi,
        },
    };

    const { object: DefaultSalesMan} = useFetchObjectByPost(
        uri + "GetD",
        tb2,
      );
      if(fetchinData || !data?.ActsCust || !newAccount || fetching)
        return <BackdropScI />
    return (
        <Customers
        {...{
            data:{...data, Acts: data?.ActsCust},
            newAccount,
            fetchinData,            
            fetchURL,
            type,
            isDialog: false,
            defaultSalman:{id:DefaultSalesMan?.salesuser?.id, name:DefaultSalesMan?.salesuser?.name,i:0,isDefault:false},
            isData:false,
        }}/>
    )
}

   