import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { uri } from "../../../help/Api";

import LoadingPage from "../../../services/LoadingPage";
// import Footer from "./Footer";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import { sto } from "../../../Context/InshData";
// import headerS from "./a4/HeaderS";
// import BodyMezan from "./a4/BodyMezan";
import FooterA from "../../Safes/Jard/A4/FooterA";
import { Grid, Typography } from "@material-ui/core";
import { stElemnt } from "../../../St/Reviews/cStyle";

import useChunkMyData from "../../../components/Hooks/useChunkMyData";
import useFetchURL from "../../../components/Hooks/useFetchURL";
import { Rept } from "../../../Context/InshData";
import HeaderLast from "./A4/HeaderLast";
import BodyLast from "./A4/BodyLast";
function OnePage(props) {
  const {chunk, isFirst,isLast ,info,loc} = props;
  
  return (
    <Grid item container style={{ ...stElemnt.AF.Reviws.pdf_page }}>

      <Fragment>
      <Grid xs={12} item  style={{ maxHeight:"27.5cm" ,minHeight:"27.5cm"}}>
        {isFirst && <HeaderLast info={info}  loc={loc} data={chunk}/>}
        <BodyLast
          data={chunk ?? []}
          isLast={isLast}
        />
        </Grid>
       
        <Grid xs={12} item  style={{ maxHeight:"1.8cm" ,minHeight:"1.8cm"}}>
          <FooterA found={info}
            isLast={isLast}/>
        </Grid>
      </Fragment>
    </Grid>
  );
}

function RevLastPaidCon(props) {
  const loc = useLocation();
  const { chunks, chunksCount } = useChunkMyData(Rept.data, 30, 37);
  const [info, setInfo] = useState({});
  const url = uri + `Foundation?branch=${sto.branchi}`;

  const { data: found, fetching } = useFetchURL({
    ...{ url, dependencyArray: [] },
  });


  const getInfo = async () => {
    const tb = {
      GetFoundation: true,
      parms: { iFoundid: 1, branch: sto.branchi },
      autos: {},
    };
    await axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setInfo(res.data.Found);
        console.log("the res is ", res.data);
      })
      .catch((error) => { });
  };
  useEffect(() => {
    getInfo();
  }, []);

  if (fetching || !info || !chunks) return <div></div>;

  return (
    <Typography component="div" style={stElemnt.AF.Reviws.ReviwsJard}>
      <Typography component="div" >

        {chunks.map((page, i) => {
          return (
            <OnePage
              {...{
                chunk: page,
                isFirst: i == 0,
                isLast: i == chunksCount - 1,
                info,
                loc
              }}
            />
          );
        })}
      </Typography>
    </Typography>
  );
}
export default RevLastPaidCon;
