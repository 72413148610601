import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { sto } from "../../../../../Context/InshData";
import { uri } from "../../../../../help/Api";
import HashLoader from "react-spinners/HashLoader";
import { colors, Container, Dialog, Modal } from "@material-ui/core";
import LoadingPage from "../../../../../services/LoadingPage";
import SearchInvoiceModal from "./SearchInvModel";
const defaultAutos = {
  Branches: [],
};

export default function SearchInvoicesMains(props) {
  const {
    openDw,
    handCloseDw,
    transition,
    showChosedInv,
    invsShow,
    stElemnt,
    style,
    gets,
    setOr,
    or,
    optionsCustomers,
    PcIdIvPc,
    setting,
    setIsClick,
    ished,
    AutoOptions,
    isHedden,
    permistionInv,
    setIshedden,
    isStock,
    isPurches,
  } = props;
 

  
  return (

    <SearchInvoiceModal
      {...{
        AutoOptions,
        openDw,
        handCloseDw,
        transition,
        showChosedInv,
        invsShow,
        stElemnt,
        style,
        gets,
        setOr,
        or,
        optionsCustomers,
        PcIdIvPc,
        setting,
        setIsClick,
        ished,
        isHedden,
        permistionInv,
        setIshedden,
        isStock,
        isPurches,
      }}
    />
  );
}
