import React, { useEffect, useState } from 'react';
import { Button, colors, Container, Grid, Hidden, LinearProgress, makeStyles, Paper, TableContainer, Typography } from '@material-ui/core';
import PaymentTransactionRPTForm from './paymentTransactionRPT-form';
import { useHistory } from 'react-router-dom';

import ScannerIcon from '@material-ui/icons/Scanner';
import KeyboardReturnSharpIcon from '@material-ui/icons/KeyboardReturnSharp';
import PrintIcon from '@material-ui/icons/Print';
import { useStylesSpDial, useStylesUiElemment } from '../../../St/Reports/UseStyle';
import moment from 'moment';
import { insh as inshst } from '../../../Context/InshSt';
import { SAdefaultValues } from './paymentTransactionRPT-services';

import PaymentTransactionRPTTable from './paymentTransactionRPT-table';
import { URL } from '../../../api/customer';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';
import { TableChart } from '@material-ui/icons';
import { CSVLink } from 'react-csv';
import TotalCard from '../../../components/TotalCard';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#faf1f1',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
  },
  blCard: {
    backgroundColor: "#fff",
    borderColor: "#81007f",
  },

  headerTitle: {
    backgroundColor: colors.red[30],
    // backgroundColor: '#ffffff',
    textAlign: 'center',
    fontSize: 20,
    width: '100%'
  },

  blCard: {
    backgroundColor: '#fff',
    borderColor: '#81007f'
  },

}));


export default function PaymentTransactionRPT(props) {
  const { AutoOptions } = props;
  let from_date = moment(new Date()).subtract().format("YYYY") + "-01-01";
  const [rptParams, setRptParams] = React.useState({ ...SAdefaultValues, account: AutoOptions?.Accindex[0], dateFrom: from_date });
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();

  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const { t } = useTranslation(["forms", "translation"]);

  const fetchDataClick = async () => {
    setFetching(true);
    const accCode = rptParams.account?.id;
    let type = rptParams.type?.id;
    if (type == 0 && accCode != -1) {
      if (accCode.toString().toLowerCase().startsWith("123"))
        type = 1;
      else type = 2;
    }
    await axios.get(URL + `/lastPay?type=${type}
        &&branch=${rptParams.branch?.id}
        &&omla=${rptParams.omla?.id}
        &&dateFrom=${rptParams?.dateFrom + ' ' + rptParams?.timeFrom}
        &&dateTo=${rptParams?.dateTo + ' ' + rptParams.timeTo}
        &&acc_code=${rptParams.account?.id}`).then(res => {
      setData(res.data);
      console.log("the data is", res.data)
    }).catch(err => {
      console.log(err);
      setFetching(false);
    });
    setFetching(false);
  }
  const [style, setStyle] = useState(inshst.custm);
  useEffect(() => {
    console.log(" the balance ", data)
  }, [])
  const his = useHistory();
  const toBack = () => { his.push({ pathname: '/', }); };
  const printHandle = () => {
    const info = {
      data: data?.lastpayData,
      tot: data?.totals,
      from: 'الحساب',
      title: 'تقرير حركة اخر سداد',
      auto: {},
      col:rptParams.type?.id==0?"العميل/المورد":rptParams.type?.id==1?"العميل":"المورد",
      fDate: rptParams.dateFrom, 
      tDate: rptParams.dateTo,
      omla:rptParams.omla.name,
      
      nDate: moment(new Date()).format('YYYY-MM-DD'),
      ElmntShow: true,
    }
    localStorage.setItem('iInfos', JSON.stringify(info));
    his.push({ pathname: '/ReviewLastPaid', });
    window.location.reload(false);
  }
  let type_cust = rptParams.type?.id;
  let label_name = "";
  let excel_name = "";

  if (type_cust == 0) {
    label_name = "العميل/المورد";
    excel_name = "paymentTransactionRPT"
  } else if (type_cust == 1) {
    label_name = "اسم العميل";
    excel_name = "CustomersPaymentTransactionRPT"
  } else {
    label_name = "اسم المورد";
    excel_name = "SuppliersPaymentTransactionRPT"
  }
  const excelHeaders = [
    { label: "رقم الحساب", key: "acc_no" },
    { label: label_name, key: "name" },
    { label: "الهاتف", key: "tel" },
    { label: "الجوال", key: "mobile" },
    { label: "قيمة اخر سداد ", key: "AmtVale" },
    { label: "تاريخ اخر سداد ", key: "Doc_date" },
    { label: "نوع المستند ", key: "docName" },
    { label: "الرصيد", key: "Blance" },
    { label: "الحالة", key: "CaseBlance" },
  ];

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  }
  return (
    <div
      className={classes.root}>
      <Grid container item xs={12}>
        <Typography size="small" className={classes.headerTitle} >
          حركة اخر سداد للعملاء / الموردين
        </Typography>
      </Grid>
      <Toaster />
      <Typography component="div" style={{ direction: 'rtl' }} >
        <Container component={Paper} maxWidth={false}
          style={{
            padding: '10px',
            paddingBottom: '0px',
            borderBottomColor: inshst.colors.cust,
            borderBottomWidth: '10px',
            borderBottomStyle: 'solid'
          }}
        >

          <PaymentTransactionRPTForm {...{ AutoOptions, rptParams, setRptParams, onChangeData, onChangeAutoData, printHandle }} />
          <Grid container justifyContent="center" lg={12} md={12} sm={12} xs={12} spacing={1} >
            <Hidden smUp={["sm"]}>
              <Grid item>
                <Button
                  disabled={fetching}
                  variant="contained"
                  startIcon={<ScannerIcon />}
                  size="large"
                  style={{ ...style.iconBtn, borderRadius: 0 }}
                  className={[clasui.button]}
                  onClick={() => fetchDataClick()}
                >
                  {t("forms:show")}
                </Button>
              </Grid>
            </Hidden>
            <Hidden only={["xs"]}>
              <Grid item>
                <Button
                  disabled={fetching}
                  variant="contained"
                  startIcon={<ScannerIcon />}
                  size="large"
                  style={{ ...style.iconBtn, borderRadius: 0 }}
                  className={[clasui.button]}
                  onClick={() => fetchDataClick()}
                >
                  {t("forms:view")}
                </Button>
              </Grid>
            </Hidden>

            <Hidden smUp={["sm"]}>
              <Grid item >
                {data && !fetching && data.lastpayData && (
                  <CSVLink
                    separator={";"}
                    filename={excel_name}
                    data={data.lastpayData}
                    headers={excelHeaders}
                  >
                    {" "}
                    <Button
                      disabled={fetching}
                      variant="contained"
                      startIcon={<TableChart />}
                      size="large"
                      color="primary"
                      style={{ ...style.iconBtn, borderRadius: 0 }}
                      className={[clasui.button]}
                    >
                      {"اكسل"}
                    </Button>
                  </CSVLink>
                )}
              </Grid>
            </Hidden>
            <Hidden only={["xs"]}>
              <Grid item >
                {data && !fetching && data.lastpayData && (
                  <CSVLink
                    separator={";"}
                    filename={excel_name}
                    data={data.lastpayData}
                    headers={excelHeaders}
                  >
                    {" "}
                    <Button
                      disabled={fetching}
                      variant="contained"
                      startIcon={<TableChart />}
                      size="large"
                      color="primary"
                      style={{ ...style.iconBtn, borderRadius: 0 }}
                      className={[clasui.button]}
                    >
                      {"تصدير اكسل"}{" "}
                    </Button>
                  </CSVLink>
                )}
              </Grid>
            </Hidden>
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                style={{ ...style.iconBtn, borderRadius: 0 }}
                className={[clasui.button]}
                onClick={printHandle}
              >
                {t("forms:print")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                style={{ ...style.iconBtn, borderRadius: 0 }}
                className={[clasui.button]}
                onClick={() => his.goBack()}
              >
                {t("forms:exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={true} style={{ padding: "4px" }}>
          <Grid container spacing={1} style={{ paddingTop: "7px" }}>

            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: classes.blCard,
                  title: "عدد السجلات",
                  value: data?.totals?.rowCounts.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: classes.blCard,
                  title: "إجمالي ارخر سداد",
                  value: data?.totals?.totLast.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: classes.blCard,
                  title: "إجمالي الرصيد ",
                  value: data?.totals?.totBalance.toFixed(2),
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <Container component={Paper} maxWidth={false}
          style={{
            padding: '10px',
            // paddingTop:'5px', 
          }}
        >
          <Grid container >
            {
              fetching ? <TableContainer component={Paper} >
                {'يرجى الانتظار قليلا حتى يتم جلب البيانات ...'}
                <LinearProgress />

              </TableContainer>
                :
                <PaymentTransactionRPTTable {...{ data: data?.lastpayData ? data?.lastpayData : [], fetchDataClick, title: label_name }} />
            }


          </Grid>
        </Container>


      </Typography>

    </div>
  );




};