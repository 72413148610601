import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  table: {
    // minWidth: 650,
    maxHeight:400,
  },
}));

export default function SalesCloseTable(props) {
  const { data, title } = props;


  
  const { t } = useTranslation(["forms"]);
  const classes = useStyles();
  const stylH = {
    BorderBottom: "solid 0.5px",
    borderColor: "black",
    fontFamily: "Amiri-Regular",
    fontWeight: "bold",
    fontSize: "1rem",
    color: "#fff"
}
const stylRo = {
    BorderBottom: "solid 0.5px",
    borderColor: "gray",
    fontFamily: "Amiri-Regular",
    fontWeight: 700,
    fontSize: 14,
    color: "#000"
}
  return (
    <TableContainer component={Paper}>
      <Table 
      // className={classes.table} 
      size="small" aria-label="a dense table"
      >
        <TableHead>
          <TableRow
           style={{
            backgroundColor: colors.green[300],
            color: "#000",
            fontWeight: "bold",
            fontFamily: "Amiri-Regular",
            fontSize: 15,
            border: "solid 0.6px"
          }}
          >
             <TableCell style={stylH} align="center">
             {"No"}
            </TableCell>
            <TableCell style={stylH} align="center">
            {"نوع العملية"}
            </TableCell>
            <TableCell style={stylH} align="center">
            {"الاجمالي"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row,i) => (
            <TableRow key={row.name}
            style={{ padding: "5px 0px",
              textAlign: "-webkit-center",
              margin: "0px",
              whiteSpace: "nowrap",
              color: "#000",
              fontWeight: 800,
              fontFamily: "Amiri-Regular",
              fontSize: 15,
              border: "solid 0.6px"}}>
              <TableCell   style={stylRo} align="center">{i+1}</TableCell>
              <TableCell   style={stylRo} align="center">{row.name}</TableCell>
              <TableCell  style={stylRo} align="center" >{row.TotalFloat?.toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
