import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  useStylesUp, useStylesHed, useStylesAuto, useStylesAutoPurch, useStylesTF, useStylesTD, useStylesBA, useStylesLbl
  , useStylesUiElemment,
  useStylesSpDial,
} from '../../../../../St/Reports/UseStyle';
export default function SalesDetailsForm(props) {
  const {
    rptParams,
    onChangeData,
    AutoOptions,
    CustomerOptions,
    onChangeAutoData,
    printHandle,
    branch,
    setBranch,
    valueType,
    setValueType,
    isPurches
  } = props;

  const { t } = useTranslation(["forms"]);
  const calsPurch = useStylesAutoPurch();
  const claslbl = useStylesLbl();
  const clasAo = useStylesAuto();
  const clasTF = useStylesTF();

  return (
    <React.Fragment>
      <Grid container style={{ padding: 5 }} spacing={1}>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            className={isPurches ? clasTF.rootPurch : clasTF.rootSale}
            InputProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrSale }}
            InputLabelProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrSale }}
            variant="outlined"
            size="small"
            id="datef"
            label={t("date-from")}
            type="date"
            format="YYYY-MM-DD"
            name="datef"
            value={rptParams.dateFrom}
            onChange={onChangeData("dateFrom")}
          />
        </Grid>
        {rptParams.selectTime.id == 2 && (
          <Grid item lg={2} md={2} sm={3} xs={6}>
            <TextField fullWidth variant="outlined" size="small" id="timeFrom" label="الوقت "
              type="time"
              format="HH:mm"
              className={isPurches ? clasTF.rootPurch : clasTF.rootSale}
              InputProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrSale }}
              InputLabelProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrSale }}
              name="timeFrom" value={rptParams.timeFrom} onChange={onChangeData('timeFrom')}
            /></Grid>
        )}
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            className={isPurches ? clasTF.rootPurch : clasTF.rootSale}
            InputProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrSale }}
            InputLabelProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrSale }}
            variant="outlined"
            size="small"
            id="dateT"
            label={t("date-to")}
            type="date"
            format="YYYY-MM-DD"
            name="datet"
            value={rptParams.dateTo}
            onChange={onChangeData("dateTo")}
          />
        </Grid>


        {rptParams.selectTime.id == 2 && (
          <Grid item lg={2} md={2} sm={3} xs={6} style={{ paddingBottom: "0.5rem" }}>
            <TextField fullWidth variant="outlined" size="small" id="timeTo" label="الوقت "
              className={isPurches ? clasTF.rootPurch : clasTF.rootSale}
              InputProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrSale }}
              InputLabelProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrSale }}
              type="time"
              format="HH:mm"
              name="timeTo" value={rptParams.timeTo} onChange={onChangeData('timeTo')}
            /></Grid>
        )}


        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            classes={isPurches ? calsPurch : clasAo}
            value={rptParams.branch}
            onChange={(event, newValue) => {
              onChangeAutoData("branch", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.Branches}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("branch")}
                variant="outlined"
                InputLabelProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrSale, shrink: true }}

              />
            )}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            classes={isPurches ? calsPurch : clasAo}
            value={rptParams.cust}
            onChange={(event, newValue) => {
              onChangeAutoData("cust", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={CustomerOptions}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrSale, shrink: true }}
                label={t("customer")}
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            classes={isPurches ? calsPurch : clasAo}
            value={rptParams.type}
            onChange={(event, newValue) => {
              onChangeAutoData("type", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.types}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ className: isPurches ? claslbl.clrBlackLblS : claslbl.clrSale, shrink: true }}
                label="طريقة الدفع"
                variant="outlined"
              />
            )}
          />
        </Grid>

      </Grid>
    </React.Fragment>
  );
}
