import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
    useStylesUp, useStylesHed, useStylesAuto, useStylesTF, useStylesTD, useStylesBA, useStylesLbl
    , useStylesUiElemment,
    useStylesSpDial
} from '../../../../../St/Reports/UseStyle';
export default function DailyReportsForm(props) {
    const {
        AutoOptions,
        rptParams,
        onChangeData,
        onChangeAutoData,
        changTField
    } = props;

    const { t } = useTranslation(["forms"]);
    const clasAo = useStylesAuto();

    const clasup = useStylesUp();
    const clashd = useStylesHed();
    const clasBA = useStylesBA();
    const clasTF = useStylesTF();
    const clasTD = useStylesTD();
    const claslbl = useStylesLbl();
    const clasui = useStylesUiElemment();
    const clasSp = useStylesSpDial();
    return (
        <React.Fragment>
            <Grid container style={{ padding: 5 }} spacing={1}>

                <Grid item lg={6} md={6} sm={6} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small" id="dateFrom" label="من تاريخ"
                        // className={clasTF.rootSale} 
                        // InputProps={{ className: clasTD.input }}
                        className={clasTF.rootSale}
                        InputProps={{ className: claslbl.clrSale}}
                        InputLabelProps={{ className: claslbl.clrSale }}
                        type="date" format="YYYY-MM-DD"
                        name="dateFrom" value={rptParams.dateFrom} onChange={changTField('dateFrom')}
                    /></Grid>
                {rptParams.selectTime.id == 2 && (
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <TextField fullWidth variant="outlined" size="small" id="timeFrom" label="الوقت "
                            className={clasTF.rootSale}
                            InputProps={{ className: claslbl.clrSale}}
                            InputLabelProps={{ className: claslbl.clrSale }}
                            type="time"
                            format="HH:mm"
                            name="timeFrom" value={rptParams.timeFrom} onChange={changTField('timeFrom')}
                        /></Grid>
                )}
                <Grid item lg={6} md={6} sm={6} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <TextField fullWidth variant="outlined" size="small" id="dateTo" label="إلى تاريخ"
                        className={clasTF.rootSale}
                        InputProps={{ className: claslbl.clrSale}}
                        InputLabelProps={{ className: claslbl.clrSale }}
                        type="date" format="YYYY-MM-DD"
                        name="dateTo" value={rptParams.dateTo} onChange={changTField('dateTo')}
                    /></Grid>

                {rptParams.selectTime.id == 2 && (
                    <Grid item lg={6} md={6} sm={6} xs={6} style={{ paddingBottom: "0.5rem" }}>
                        <TextField fullWidth variant="outlined" size="small" id="timeTo" label="الوقت "
                            className={clasTF.rootSale}
                            InputProps={{ className: claslbl.clrSale}}
                            InputLabelProps={{ className: claslbl.clrSale }}
                            type="time"
                            format="HH:mm"
                            name="timeTo" value={rptParams.timeTo} onChange={changTField('timeTo')}
                        /></Grid>
                )}

                <Grid item lg={6} md={6} sm={6} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete classes={clasAo}
                        value={rptParams.safe}
                        labelPlacement=""
                        onChange={(event, newValue) => { onChangeAutoData('safe', newValue) }}
                        id="controllable-states-demo" size="small"
                        options={AutoOptions.Safes}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                        // style={{  }}
                        disableClearable
                        renderInput={(params) => <TextField {...params} label="المخزن" variant="outlined" size="small"
                            InputLabelProps={{ className: claslbl.clrSale }} />}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete classes={clasAo}
                        value={rptParams.stock}
                        labelPlacement=""
                        onChange={(event, newValue) => { onChangeAutoData('stock', newValue) }}
                        id="controllable-states-demo" size="small"
                        options={AutoOptions.Stocks}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                        // style={{  }}
                        disableClearable
                        renderInput={(params) => <TextField {...params} label="الحساب" variant="outlined" size="small"
                            InputLabelProps={{ className: claslbl.clrSale }} />}
                    />


                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete classes={clasAo}
                        value={rptParams.branch}
                        onChange={(event, newValue) => {
                            //   setBranch(newValue);
                            onChangeAutoData("branch", newValue);

                        }}
                        id="combo-box-demo"
                        size="small"
                        options={AutoOptions.Branches}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                        }
                        style={{}}
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{ shrink: true,className:claslbl.clrSale }}
                                label={t("branch")}
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={6} style={{ paddingBottom: "0.5rem" }}>
                    <Autocomplete classes={clasAo}
                        value={rptParams.user}
                        onChange={(event, newValue) => {
                            onChangeAutoData("user", newValue);
                        }}
                        id="combo-box-demo"
                        size="small"
                        options={AutoOptions.Users}
                        //getOptionLabel={(option) => option.name}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                        }
                        style={{}}
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{ shrink: true,className:claslbl.clrSale }}
                                label={t("user")}
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>

            </Grid>
        </React.Fragment>
    );
}
