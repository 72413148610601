import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { stElemnt } from "../../../../St/Reviews/cStyle";
const useStyles = makeStyles((theme) => ({
  table: {
    fontFamily: "Amiri-Regular",
    direction: "rtl",
   
  },
  tableRightBorder: {
    ...stElemnt.AF.header,
    fontWeight: "bold",
    paddingTop: "2px",
    paddingBottom:"2px",
    border: "solid 0px",
    border: "solid 0.1px",
  },
  rowHeader: {
    fontFamily: "Amiri-Regular",
    fontWeight: "bold",
    paddingTop: "2px",
    paddingBottom:"2px",
    border: "solid 0px",
    border: "solid 0.1px",
  },
  headerrows:{
    ...stElemnt.AF.header,
    backgroundColor: colors.grey[100],
    fontWeight: "bold",
    paddingTop: "2px",
    paddingBottom:"2px",
    border: "solid 0px",
    border: "solid 0.1px",
  },
  rowsStyle:{
    ...stElemnt.AF.cell,
    padding: "0px 1px",
  }

}));
const styla={ 
    ...stElemnt.AF.header,
    backgroundColor: colors.grey[100],
    fontWeight: "bold",
    paddingTop: "2px",
    paddingBottom:"2px",
    border: "solid 0px",
    border: "solid 0.1px",};
export default function BodyBalnce(props) {
  const { data } = props;
  const { t } = useTranslation(["forms"]);

  const classes = useStyles();
  return (
    <TableContainer component={Paper} style={{ paddingTop:"1rem"}}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow
            style={styla}
          >
            <TableCell className={classes.tableRightBorder} align="center" colSpan={2}></TableCell>

            <TableCell
              className={classes.tableRightBorder}
              align="center"
              colSpan={2}
            >
              {t("prev-balance")}
            </TableCell>
            <TableCell
              className={classes.tableRightBorder}
              align="center"
              colSpan={2}
            >
              {" "}
              {t("acc-move")}
            </TableCell>
            <TableCell
              className={classes.tableRightBorder}
              align="center"
              colSpan={2}
            >
              {t("acc-balance")}
            </TableCell>
          </TableRow>
          <TableRow
          style={{styla}}
          >
            <TableCell className={classes.tableRightBorder} align="center">
              {t("account-code")}
            </TableCell>
            <TableCell className={classes.tableRightBorder} align="center">
              {t("account")}
            </TableCell>
          
            <TableCell
              className={classes.tableRightBorder}
              style={{  ...stElemnt.AF.cell,
                padding: "0px 1px", }}
              align="center"
            >
              {" "}
              {t("dept")}{" "}
            </TableCell>
            <TableCell className={classes.tableRightBorder} align="center">
              {" "}
              {t("credit")}
            </TableCell>
            <TableCell className={classes.tableRightBorder} align="center">
              {" "}
              {t("dept")}{" "}
            </TableCell>
            <TableCell className={classes.tableRightBorder} align="center">
              {" "}
              {t("credit")}{" "}
            </TableCell>
            <TableCell className={classes.tableRightBorder} align="center">
              {" "}
              {"الرصيد"}{" "}
            </TableCell>
            <TableCell className={classes.tableRightBorder} align="center">
              {" "}
              {"الحالة"}{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.Nature} 
            style={{ ...stElemnt.AF.cell,
                padding: "0px 1px",}}>
              <TableCell align="center" className={classes.rowsStyle}>
                {row.Code}
              </TableCell>
              <TableCell align="center" className={classes.rowsStyle}>
                {row.aname}
              </TableCell>

              <TableCell align="center" className={classes.rowsStyle}>
                {parseFloat(row?.OBX_Dept)?.toFixed(2)?? "0.00"}
              </TableCell>
              <TableCell align="center" className={classes.rowsStyle}>
              {parseFloat(row?.OBX_Credit)?.toFixed(2)?? "0.00"}
              </TableCell>
              <TableCell align="center" className={classes.rowsStyle}>
                {parseFloat(row?.dept)?.toFixed(2) ?? "0.00"}
              </TableCell>
              <TableCell align="center" className={classes.rowsStyle}>
                {parseFloat(row?.credit)?.toFixed(2) ?? "0.00"}
              </TableCell>
              <TableCell align="center" className={classes.rowsStyle}>
              {(parseFloat(row?.end_Dept) + parseFloat(row?.end_Credit))?.toFixed(2) ?? "0.00"}
              </TableCell>
              <TableCell align="center" className={classes.rowsStyle}>
              {  parseFloat(row?.end_Dept) > 0 ? "مدين" : "دائن"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
