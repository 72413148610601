import React, { useState, useEffect } from "react";

import {
  Button,
  Chip,
  colors,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";

import { uri } from "../../../../help/Api";
import TotalCard from "../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
  useStylesHed,
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../St/comps/UseStyle";
import { insh as inshst } from "../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import i18next from "i18next";
import { TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import moment from "moment";
import axios from "axios";
import { sto } from "../../../../Context/InshData";
import { typeCosts, typeQties } from "../../../../services/safe-grd-services";

import deepRenameKeys from "deep-rename-keys";
import GardUnitsForm from "./GardUnitsForm";
import GardUnitsTable from "./GardUnitsTable";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    backgroundColor: colors.green[50],
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
  purchTotalCard: {
    backgroundColor: colors.blue[50],
    borderColor: colors.blue[300],
  },
}));

export default function GardUnit(props) {
  const { AutoOptions } = props;
  let idSafe=AutoOptions.Safes[0].id;
  let nameSafe=AutoOptions.Safes[0].name;

  let isbranch=AutoOptions.Branches[0].id;
  let namebranch=AutoOptions.Branches[0].name;
  const typeQties = [
    { id: 0,  name: "الكل" },
    { id: 1,name: "الاصناف ما عدا السالبة" },
    { id: 2, name: "الأصناف ما عدا الصفرية" },
    { id: 3,name: "الاصناف ما عدا السالبة والصفرية" },
  ];
  const typeItems = [
    { id: 0, name: "الكل" },
    { id: 1, name: "مخزني" },
    { id: 2, name: "خام" },
    { id: 3,name: "مصنعي" },
  ];
   const defaultGRDparmsEmp = {
    group: { id:-1, name:  "الكل" },
    safe: { id: sto.User_typei==1? -1: idSafe, name: sto.User_typei==1? "الكل":nameSafe },
    branch: {id:sto.User_typei==1? -1:isbranch, name:sto.User_typei?'الكل':namebranch},  
    typeItem:typeItems[0],
    typeQun:typeQties[0],
  };
 
  const [rptParams, setRptParams] = React.useState(defaultGRDparmsEmp);
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const { t } = useTranslation(["forms", "translation"]);

  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [sum, setSum] = useState(false);
  const viewHandle = async () => {

    setData([]);
    setFetching(true);
    await axios
      .get(
        uri +
        `RptSafes/GardUnits?branch=${rptParams.branch?.id}
  &&safe=${rptParams.safe?.id}
  &&group_id=${rptParams.group?.id}
  &&typeQtys=${rptParams.typeQun?.id}
  &&typeItems=${rptParams.typeItem?.id}`
      )
      .then((res) => {
       setData(res.data);
        console.log(" the data is", res.data);
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);
        console.log(err);
        // toast.err("يوجد خطأ في الجلب");
        setData([]);
      });
  };

  const his = useHistory();

 
  const printHandle = () => {
    const info = {
      columns: columns,
      // // rout:viewPrms,
      title: "كشف جرد المخازن بالوحدات",
      data: data?.gardUnits,
     safe:rptParams.safe?.name,
     branch:rptParams.branch?.name,
     group:rptParams.group?.name,
      tot:data?.totals,
      nDate: moment(new Date()).format("YYYY-MM-DD"),
      ElmntShow: false,
    };
    //console.log(info)
    localStorage.setItem("iInfos", JSON.stringify(info));
    his.push({ pathname: "/ReviewJardUnits" });
    window.location.reload(false);
  };
  let excel_name= "SafeGardUnitsReport" ;
  var columns = [
    { title: "الرقم", field: "currency_from" },
    { title: "الباركود", field: "barcode" },
    { title: "الصنف", field: "Name_Item" },
    { title: "الرصيد", field: "dec_Qtys" },
    { title: "متوسط التكلفة", field: "Csot" },
    { title: "إجمالي التكلفة", field: "Sum_Cost" },
    { title:"سعر البيع", field: "sale_price" },
    { title:"اجمالي سعر البيع", field: "Sum_sale_price" },

  ];

  const excelHeaders = deepRenameKeys(columns, (key) => {
    if (key === "field") return "key";
    if(key === "title") return "label";  
  });

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };
  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography
          size="small"
          className={classes.headerTitle}
          color="primary"
        >
          { "تقرير جرد المخزن بالوحدات "} 
        </Typography>
      </Grid>
      <Typography
        component="div"
        style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
      >
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            paddingBottom: "0px",
            borderBottomColor:  colors.blue[300] ,
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
=          <GardUnitsForm
            {...{
              AutoOptions,
              rptParams,
              onChangeData,
              onChangeAutoData,
              typeQties,
              typeItems,
            }}
          />    
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                size="large"
                style={
                  ( inshst.purch.iconBtn,
                  { borderRadius: 0 })
                }
                className={[clasui.button]}
                onClick={() => viewHandle()}
              >
                {t("forms:view")}
              </Button>
            </Grid>
            <Grid item >
            {data && !fetching && data.gardUnits && (
                <CSVLink
                  separator={";"}
                  filename={excel_name}
                  data={data?.gardUnits}
                  headers={excelHeaders}
                >
                  {" "}
                  <Button
                    disabled={fetching}
                    variant="contained"
                    startIcon={<TableChart />}
                    size="large"
                    color="primary"
                    style={{ borderRadius: 0 }}
                    className={[clasui.button]}
                  >
                    {"تصدير اكسل"}{" "}
                  </Button>
                </CSVLink>
              )}
            </Grid>
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.button, clasSp.clSec]}
                style={
                  ( inshst.purch.iconBtn ,
                  { borderRadius: 0 })
                }
                onClick={printHandle}
              >
                {t("forms:print")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                style={
                  ( inshst.purch.iconBtn,
                  { borderRadius: 0 })
                }
                onClick={() => his.goBack()}
              >
                {t("forms:exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={true} style={{ padding: "4px" }}>
          <Grid container spacing={1} style={{ paddingTop: "10px" }}>
          <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: classes.purchTotalCard ,
                  title:"إجمالي عدد السجلات",
                  value: data?.totals?.cntTotal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className:  classes.purchTotalCard ,
                  title:" إجمالي الكميات (افتراضي)",
                  value: data?.totals?.qtyTotal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className:  classes.purchTotalCard ,
                  title:"إجمالي الكميات",
                  value: data?.totals?.vatTotal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className:  classes.purchTotalCard ,
                  title:"إجمالي التكلفة",
                  value: data?.totals?.costTotal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className:  classes.purchTotalCard ,
                  title:"إجمالي البيع",
                  value: data?.totals?.totNetTotal.toFixed(2),
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <br />
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <BeatLoader loading={fetching} color={colors.green[300]} />
              {!fetching && (
                <GardUnitsTable
                  {...{
                    data: data?.gardUnits ? data?.gardUnits : [],
                    rptParams,
                }}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Typography>
    </div>
  );
}
